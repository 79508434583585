import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';

interface PopoverInterface {
  PopoverBodyText: string;
  PopoverHeaderText: string;
  PopoverTriggerComponent: React.ReactNode;
}
export function PopoverComponent({ PopoverBodyText, PopoverHeaderText, PopoverTriggerComponent }: PopoverInterface) {
  return (
    <Popover>
      <PopoverTrigger>{PopoverTriggerComponent}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{PopoverHeaderText}</PopoverHeader>
        <PopoverBody>{PopoverBodyText}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
