import { Box, Center, Heading, Text } from '@chakra-ui/react';
import { t } from 'i18next';

export function AccessDenied() {
  return (
    <Center>
      <Box>
        <Center>
          <Heading>{t('unauthorized')}</Heading>
        </Center>
        <Text>{t('supposed_to_have_access')}</Text>
      </Box>
    </Center>
  );
}
