import { useEffect, useRef, useState } from 'react';
import { Box, Center, Divider, Grid, GridItem } from '@chakra-ui/react';
import { FlagCard } from '../../components/flags/FlagCard';
import { AllFlags } from '../../apiService';
import { FlagDataDTO } from '../../dto';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { SortMenu } from '../../components/flags/SortMenu';
import { PageSelector } from '../../components/flags/PageSelector';
import { QueryString } from '../../components/api/QueryBuilder';

export function StatusPage() {
  const [flagData, setFlagData] = useState<FlagDataDTO>({ data: [], meta: { total_length: 0 } });
  const [filterInactive, setFilterInactive] = useState<boolean>(false);
  const [sortingMode, setSortingMode] = useState<string | null>(null);

  const gridRef = useRef<HTMLDivElement | null>(null);
  const flagsPerPage = Math.max(1, Math.floor((gridRef.current as HTMLDivElement)?.offsetWidth / 404)) * 10;
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setSortingMode('-time');
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * flagsPerPage;
    const endIndex = startIndex + flagsPerPage;
    const query = new QueryString();

    if (!isNaN(startIndex) && !isNaN(endIndex)) {
      query.add_kwarg('range', startIndex + '-' + endIndex);
      if (sortingMode) {
        query.add_kwarg('order_by', sortingMode);
      }
      if (!filterInactive) {
        query.add_kwarg('is_active', 'true');
      }

      AllFlags(query.query_string).then((r) => {
        setFlagData(r);
      });
    }
  }, [currentPage, filterInactive, flagsPerPage, sortingMode]);

  if (!flagData) {
    return <LoadingSpinner />;
  }

  return (
    <Box padding={'2px'}>
      <SortMenu filterInactive={filterInactive} setFilterInactive={setFilterInactive} setSortingMode={setSortingMode} />
      <Divider />
      <Center>
        <Grid
          templateColumns="repeat(auto-fill, 404px)"
          w="100%"
          h="100%"
          paddingTop="2%"
          justifyContent={'center'}
          gap={'20px'}
          ref={gridRef}
        >
          {flagData.data.map((flag) => (
            <GridItem key={flag.id + '_flag'}>
              <FlagCard setFlagData={setFlagData} flag={flag} flagData={flagData} />
            </GridItem>
          ))}
        </Grid>
      </Center>
      <PageSelector
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={Math.ceil((flagData.meta.total_length || 0) / flagsPerPage)}
      />
    </Box>
  );
}
