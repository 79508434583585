import { UserGraphComponent } from '../../../components/admin/graphs/UserGraphComponent';
import { HubsGraphComponent } from '../../../components/admin/graphs/HubsGraphComponent';
import { SimpleGrid } from '@chakra-ui/react';
import { FlagGraphComponent } from '../../../components/admin/graphs/FlagGraphComponent';
import { TotalFlagsComponent } from '../../../components/admin/graphs/TotalFlagsComponent';

export function DisplayInfoAdmin() {
  return (
    <SimpleGrid columns={{ md: 2, sm: 1 }} spacing={15}>
      <UserGraphComponent />
      <HubsGraphComponent />
      <FlagGraphComponent />
      <TotalFlagsComponent />
    </SimpleGrid>
  );
}
