import { useEffect, useState } from 'react';
import { GetHubPermissions, GetProfileAdminPicture, GetUserAPI } from '../../apiService';
import { AdminUserViewDTO, HubDTO, HubsPermissionsDTO } from '../../dto';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { iquColor } from '../../styles';
import { UserCardAdmin } from './user/UserCardAdmin';
import { IoMdAdd } from 'react-icons/io';
import { t } from 'i18next';

interface AssignToHubInterface {
  hub: HubDTO;
}

interface PermissionsCardInterface {
  permission: HubsPermissionsDTO;
}

interface ContactCardInterface {
  user_id: string;
}

function ConactCard({ user_id }: ContactCardInterface) {
  const [user, setUser] = useState<AdminUserViewDTO>();
  useEffect(() => {
    GetUserAPI(user_id).then((r) => setUser(r));
  }, [user_id]);

  if (!user) {
    return <LoadingSpinner />;
  }
  return <UserCardAdmin user={user} />;
}

export function PermissionsCard({ permission }: PermissionsCardInterface) {
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    GetProfileAdminPicture(permission.user__id.toString()).then((r) => {
      setProfilePicture(r);
    });
  }, [permission.user__id]);

  return (
    <Card boxShadow="md">
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ConactCard user_id={permission.user__id} />
        </ModalContent>
      </Modal>
      <CardBody onClick={onOpen}>
        <HStack spacing={'10px'}>
          {profilePicture ? <Avatar size={'md'} src={profilePicture} /> : <Avatar size={'md'} bg={iquColor} />}
          <Text>
            {permission.user__first_name} {permission.user__last_name}
          </Text>
        </HStack>
      </CardBody>
    </Card>
  );
}

export function AssignToHub({ hub }: AssignToHubInterface) {
  const [permissions, setPermissions] = useState<[HubsPermissionsDTO]>();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inviteID, setInviteID] = useState<string>();

  useEffect(() => {
    setIsLoading(true);
    GetHubPermissions(hub.hub_id).then((res) => {
      setPermissions(res);
      setIsLoading(false);
    });
  }, [hub.hub_id]);

  if (!permissions || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Card>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader>{t('add_user')}</ModalHeader>
          <ModalBody>
            <Input
              placeholder={t('user_id')}
              value={inviteID}
              onChange={(e) => {
                setInviteID(e.target.value);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <CardHeader>
        <Text>
          {t('contacts_for')} {hub.hub_name}
        </Text>
        <Box top={3} right={3} position={'absolute'} zIndex={10}>
          <IconButton onClick={onOpen} aria-label={t('add_user')} icon={<IoMdAdd size={20} />} />
        </Box>
      </CardHeader>
      <Divider />
      <CardBody
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray',
            borderRadius: '24px',
          },
        }}
      >
        <SimpleGrid minChildWidth={'30vh'} spacing={'10px'}>
          {permissions.map((permission, key) => {
            return <PermissionsCard key={key} permission={permission} />;
          })}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
}
