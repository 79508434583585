import { Avatar } from '@chakra-ui/react';
import { iquColor } from '../../styles';
import { useGlobalUser } from './useContext';

export function ProfilePicture() {
  const { profilePic } = useGlobalUser();

  if (profilePic) {
    return <Avatar src={profilePic} />;
  }
  return <Avatar bg={iquColor} />;
}
