import React, { useEffect } from 'react';
import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Show,
  Spacer,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FiChevronDown, FiHome, FiMenu } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { iquColor } from '../../styles';
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs';
import { HiOutlineStatusOnline } from 'react-icons/hi';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { Status } from '../stats/Status';
import { NotificationsPanel } from '../notifications/NotificationsPanel';
import Cookies from 'js-cookie';
import IQULogo from '../../assets/images/iqu_ims_logo.svg';
import { ProfilePicture } from '../user/ProfilePicture';
import { useGlobalAuth } from '../login/AuthContext';
import { useGlobalUser } from '../user/useContext';
import { LanguageSelector } from '../translation/LanguageSelector';
import { t } from 'i18next';

type SidebarWithHeaderProps = {
  children: React.ReactNode;
};

interface LinkItemProps {
  name: string;
  url: string;
  icon: IconType;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
  href: string;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const LinkItems: Array<LinkItemProps> = [
    { name: t('home'), url: '/', icon: FiHome },
    { name: t('all_cores'), url: '/core', icon: AiOutlineCloudServer },
    { name: t('status'), url: '/status', icon: HiOutlineStatusOnline },
    { name: t('admin'), url: '/admin', icon: MdOutlineAdminPanelSettings },
  ];

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('gray.100', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: '12vw' }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image boxSize="100%" src={IQULogo} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <Link to={link.url} key={link.name}>
          <NavItem key={link.name} href={link.url} icon={link.icon}>
            {link.name}
          </NavItem>
        </Link>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: iquColor,
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { user } = useGlobalUser();
  const { colorMode, toggleColorMode } = useColorMode();

  const { Logout } = useGlobalAuth();
  const HandleColorMode = () => {
    toggleColorMode();
  };

  useEffect(() => {
    Cookies.set('theme', colorMode, { expires: 365 });
  }, [colorMode]);

  return (
    <Flex
      ml={{ base: 0, md: '12vw' }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('gray.100', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <Show above={'md'}>
        <Status />
      </Show>
      <Spacer />
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Box display={{ base: 'flex', md: 'none' }} fontSize="2xl" fontFamily="monospace" fontWeight="bold">
        <Image boxSize={'40%'} src={IQULogo} />
      </Box>
      <HStack spacing={{ base: '0', md: '6' }}>
        <LanguageSelector />
        <IconButton
          onClick={HandleColorMode}
          size="lg"
          variant="ghost"
          aria-label="Light/Dark "
          icon={colorMode === 'light' ? <BsFillMoonFill /> : <BsSunFill />}
        />
        <NotificationsPanel />
        <Flex alignItems={'center'} zIndex={999}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <ProfilePicture />
                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm">{user?.username}</Text>
                  {user?.is_staff ? (
                    <Text fontSize="xs" color="gray.600">
                      {t('admin')}
                    </Text>
                  ) : (
                    <Text fontSize="xs" color="gray.600">
                      {t('employee')}
                    </Text>
                  )}
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <Link to={'/profile'}>
                <MenuItem>{t('profile')}</MenuItem>
              </Link>
              <MenuDivider />
              <MenuItem onClick={Logout}>{t('sign_out')}</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export const SidebarWithHeader: React.FC<SidebarWithHeaderProps> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose}>
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: '6vw', md: '12vw' }} p="4" width={'87vw'} maxHeight={'90vh'}>
        {children}
      </Box>
    </Box>
  );
};
