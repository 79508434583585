import { Divider, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { ViewedNotification } from '../../apiService';
import { NotificationDTO } from '../../dto';
import { t } from 'i18next';

interface MessageWindowInterface {
  notification: NotificationDTO;
  setNotifications: (data: NotificationDTO[]) => void;
  notifications: NotificationDTO[];
}

export function MessageWindow({ setNotifications, notifications, notification }: MessageWindowInterface) {
  function DeleteNotification(notificationId: number) {
    setNotifications(notifications.filter((notification: { id: number }) => notification.id !== notificationId));
  }

  function CloseMessage() {
    ViewedNotification(notification.id, true);
    DeleteNotification(notification.id);
  }
  return (
    <Modal isOpen={true} onClose={CloseMessage} isCentered>
      <ModalOverlay />
      <ModalContent h={'200px'}>
        <ModalHeader>{t('message')}</ModalHeader>
        <ModalBody>
          <Flex h={'100%'} flexDirection={'column'}>
            <Flex>
              <Text>{notification.message}</Text>
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'end'} align={'end'} h={'100%'}>
              <Divider />
              <Text>{new Date(notification.time).toLocaleString(undefined, { hour12: false })}</Text>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
