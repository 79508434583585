import { NotificationDTO } from '../../dto';
import { Box, IconButton, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BellIcon } from '../nav/Bell';
import { GetNotifications } from '../../apiService';
import { Notifications } from './Notifications';

export function NotificationsPanel() {
  const [notifications, setNotifications] = useState<NotificationDTO[]>([]);
  useEffect(() => {
    GetNotifications().then((r) => {
      setNotifications(r.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()));
    });
  }, []);

  const hasNotifications = notifications.filter((item) => !item.viewed).length > 0;
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          icon={<BellIcon shouldAnimate={hasNotifications} />}
          size="lg"
          variant="ghost"
          aria-label="Light/Dark "
        />
      </PopoverTrigger>
      <PopoverContent w={'100%'} right={'5%'} borderWidth={'2px'}>
        <Box w={{ base: '90vw', md: '500px' }}>
          <Box
            overflowY={'auto'}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'gray',
                borderRadius: '24px',
              },
            }}
            h={'330px'}
          >
            <Notifications
              notifications={notifications}
              setNotifications={setNotifications}
              hasNotifications={hasNotifications}
            />
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
}
