import React from 'react';
import { useGlobalAuth } from './AuthContext';
import { LoginPage, TwoFactorPage } from '../../sites/auth/Login';
import { UserProvider } from '../user/useContext';

type AppPrivateProps = {
  children: React.ReactNode;
};

export const ProtectedRoute = ({ children }: AppPrivateProps): React.ReactElement | null => {
  const { loggedIn, isTwoFactor } = useGlobalAuth();
  if (!loggedIn) {
    return <LoginPage />;
  }
  if (!isTwoFactor) {
    return <TwoFactorPage />;
  }
  return <UserProvider>{children}</UserProvider>;
};
