import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Profile } from './sites/user/Profile';
import { HomePage } from './sites/home/Home';
import { AllHubsPage } from './sites/hub/AllHubs';
import { SidebarWithHeader } from './components/nav/NavDrawer';
import { AdminPage } from './sites/admin/AdminPage';
import { UserView } from './sites/admin/users/UserView';
import { StatusPage } from './sites/status/Status';
import { ManageFlagPage } from './sites/status/ManageFlag';
import { ManageHub } from './sites/hub/hubChildren/ManageHub';
import { LoadingSpinner } from './components/loading/LoadingSpinner';
import { NotFound404 } from './components/StatusPages/NotFound404';
import { AddUser } from './sites/admin/users/AddUser';
import { CreateProfile } from './sites/hidden/user/CreateProfile';
import { DisplayInfoAdmin } from './sites/admin/hubs/DisplayInfoAdmin';
import { BigHubCounterComponent } from './components/admin/graphs/BigHubCounterComponent';
import { ProtectedRoute } from './components/login/PrivateRoute';
import { useTranslation } from 'react-i18next';

export const App = () => {
  useTranslation();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/invite/:inviteId" element={<CreateProfile />} />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <SidebarWithHeader>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/core" element={<AllHubsPage />} />
                  <Route path="/core/:hub_id" element={<ManageHub />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/admin" element={<AdminPage />} />
                  <Route path="/admin/users" element={<UserView />} />
                  <Route path="/status" element={<StatusPage />} />
                  <Route path="/core/:hub_id/flag/:flag_id" element={<ManageFlagPage />} />
                  <Route path="/loading/" element={<LoadingSpinner />} />
                  <Route path="/admin/add-user/" element={<AddUser />} />
                  <Route path="/admin/stats/" element={<DisplayInfoAdmin />} />
                  <Route path={'/admin/big-stat'} element={<BigHubCounterComponent />} />

                  <Route path="*" element={<NotFound404 />} />
                </Routes>
              </SidebarWithHeader>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
