import { AdminUserViewDTO } from '../../../dto';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalContent,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { GetProfileAdminPicture } from '../../../apiService';
import { iquColor } from '../../../styles';
import { Link } from 'react-router-dom';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import { MdDateRange, MdVerified } from 'react-icons/md';
import { FcKey } from 'react-icons/fc';
import { UserPermissions } from './UserPermissions';
import { UserEditModal } from './UserEditModal';
import { t } from 'i18next';

interface UserCardAdminInterface {
  user: AdminUserViewDTO;
  showSettings?: boolean | null;
}

export function UserCardAdmin({ user, showSettings }: UserCardAdminInterface) {
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [userType, setUserType] = useState('user');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    GetProfileAdminPicture(user.id.toString()).then((r) => {
      setProfilePicture(r);
    });
  }, [user.id]);

  useEffect(() => {
    if (user.is_superuser) {
      setUserType('Super Administrator');
    } else if (user.is_staff) {
      setUserType('Administrator');
    } else if (user.is_technician) {
      setUserType('Technician');
    } else {
      setUserType('User');
    }
  }, [user.is_staff, user.is_superuser, user.is_technician]);

  return (
    <Card>
      <CardHeader>
        {showSettings ? (
          <Box top={0} right={0} position={'absolute'}>
            <UserEditModal user={user} />
          </Box>
        ) : null}
        <Center>
          <VStack>
            <Heading fontSize={'150%'}>
              {user.first_name ? user.first_name : user.username} {user.last_name}
            </Heading>
            {profilePicture ? <Avatar size={'xl'} src={profilePicture} /> : <Avatar size={'xl'} bg={iquColor} />}
            <HStack spacing={'15px'}>
              <Tooltip label={user.email} fontSize="md">
                <Link to={'mailto:' + user.email}>
                  <AiOutlineMail size={30} />
                </Link>
              </Tooltip>
              <Tooltip label={user.phone_number} fontSize="md">
                <Link to={t('tel') + ':' + user.phone_number}>
                  <AiOutlinePhone size={30} />
                </Link>
              </Tooltip>
            </HStack>
            <Text>{userType}</Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider />
      <CardBody>
        <Text as={'b'}>{t('info')}</Text>
        <Tooltip label={'username'}>
          <HStack>
            <BiUserCircle size={15} />
            <Text>{user.username} </Text>
            {user.is_verified ? <MdVerified color={'#1DA1F2'} /> : <MdVerified color={'red'} />}
          </HStack>
        </Tooltip>
        <Tooltip label={'Last login'}>
          <HStack>
            <MdDateRange size={15} />
            <Text>
              {user.last_login ? new Date(user.last_login).toLocaleString(undefined, { hour12: false }) : 'None'}{' '}
            </Text>
          </HStack>
        </Tooltip>
        <Tooltip label={'Id'}>
          <HStack>
            <FcKey size={15} />
            <Text fontSize={'75%'}>{user.id} </Text>
          </HStack>
        </Tooltip>
      </CardBody>
      <Divider />
      <CardFooter>
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
          <ModalContent>
            <UserPermissions user_id={user.id} />
          </ModalContent>
        </Modal>
        <Button width={'100%'} onClick={onOpen}>
          {t('cores')}
        </Button>
      </CardFooter>
    </Card>
  );
}
