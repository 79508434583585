import { Box, chakra, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { HubDTO } from '../../dto';
import { MdLocationOn, MdOutlineHub } from 'react-icons/md';
import { SiPowershell } from 'react-icons/si';
import { BiSolidUser } from 'react-icons/bi';
import { PiKeyboardBold } from 'react-icons/pi';

interface HubInfoWindowInterface {
  hub?: HubDTO;
}

export function MapMarkerWindow({ hub }: HubInfoWindowInterface) {
  if (!hub) {
    return <Text>Hub not found</Text>;
  }

  return (
    <Box
      w="100%"
      mx="auto"
      bg="white"
      _dark={{
        bg: 'gray.800',
      }}
      roundedBottom="lg"
      overflow="hidden"
    >
      <Flex
        alignItems="center"
        px={6}
        py={3}
        bg="gray.100"
        _dark={{
          bg: 'gray.900',
        }}
      >
        <Icon as={MdOutlineHub} h={6} w={6} />
        <Heading mx={3} fontWeight="bold" fontSize="lg">
          {hub.hub_name}
        </Heading>
      </Flex>
      <Box py={4} px={6}>
        <Flex alignItems="center" mt={4}>
          <Icon as={PiKeyboardBold} h={6} w={6} mr={2} />
          <Heading fontSize="sm" px={2}>
            {hub.hub_id}
          </Heading>
        </Flex>
        <Flex alignItems="center" mt={4}>
          <Icon as={BiSolidUser} h={6} w={6} mr={2} />
          <chakra.h1 px={2} fontSize="sm">
            {hub.user_id ? hub.user_id : 'No technician associated'}
          </chakra.h1>
        </Flex>
        <Flex alignItems="center" mt={4}>
          <Icon as={MdLocationOn} h={6} w={6} mr={2} />
          <chakra.h1 px={2} fontSize="sm">
            {hub.address}, {hub.post_code}, {hub.city}
          </chakra.h1>
        </Flex>
        <Flex alignItems="center" mt={4}>
          <Icon as={SiPowershell} h={6} w={6} mr={2} />
          <chakra.h1 px={2} fontSize="sm">
            {hub.ipaddress}
          </chakra.h1>
        </Flex>
      </Box>
    </Box>
  );
}
