import { Box, SimpleGrid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AllFlags, AllHubs } from '../../apiService';
import { FlagDto, HubDTO } from '../../dto';
import { IsOnline } from '../TimeUtils/TimeUtils';
import { IoIosStats } from 'react-icons/io';
import { HubStatsCard } from './HubsStatsCard';
import { RiBaseStationLine } from 'react-icons/ri';
import { criticalColor, goodColor, warningColor } from '../../styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { t } from 'i18next';

function OnlinePercent(hubs: HubDTO[]) {
  let onlineHubs = 0;
  hubs.map((hub) => {
    if (IsOnline(hub)) {
      onlineHubs += 1;
    }
  });

  return (onlineHubs / hubs.length) * 100;
}

export function Status() {
  const [onlinePercentage, setOnlinePercentage] = useState(100.0);
  const [hubs, setHubs] = useState<HubDTO[]>();
  const [numberOfHubsWithProblems, setNumberOfHubsWithProblems] = useState(0);
  const [flags, setFlags] = useState<FlagDto[]>([]);

  useEffect(() => {
    const fetchInfo = () => {
      AllFlags().then((r) => {
        setFlags(r.data);
      });
      AllHubs().then((r) => {
        setHubs(r);
      });
    };
    fetchInfo();
    setInterval(() => fetchInfo(), 60 * 1000);
  }, []);

  useEffect(() => {
    if (hubs) {
      setOnlinePercentage(OnlinePercent(hubs));
    }

    setNumberOfHubsWithProblems(
      Array.from(new Set(flags.filter((flag) => flag.is_active).map((flag) => flag.hub))).length,
    );
  }, [flags, hubs]);

  const getStatusColor = () => {
    if (onlinePercentage == 100) {
      return goodColor;
    } else if (onlinePercentage >= 90) {
      return warningColor;
    } else {
      return criticalColor;
    }
  };

  const getErrorColor = () => {
    if (numberOfHubsWithProblems == 0) {
      return goodColor;
    }
    if (numberOfHubsWithProblems == 1) {
      return warningColor;
    }
    if (numberOfHubsWithProblems >= 2) {
      return criticalColor;
    }
  };

  if (!hubs) {
    return <LoadingSpinner />;
  }

  return (
    <Box maxW="7xl" mx={'auto'} px={{ base: 2, sm: 12, md: 17 }}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <HubStatsCard title={t('number_of_cores')} stat={hubs.length.toString()} icon={<IoIosStats />} />
        <HubStatsCard
          title={t('online')}
          stat={onlinePercentage.toFixed(1) + '%'}
          icon={<RiBaseStationLine />}
          color={getStatusColor()}
        />
        <HubStatsCard
          title={t('cores_with_flags')}
          stat={numberOfHubsWithProblems.toString()}
          icon={<IoIosStats />}
          color={getErrorColor()}
        />
      </SimpleGrid>
    </Box>
  );
}
