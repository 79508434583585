import { useEffect, useState } from 'react';
import { AdminUserViewDTO } from '../../../dto';
import { AllUserAPI } from '../../../apiService';
import { GraphsComponent } from './GraphsComponent';
import { t } from 'i18next';

export function UserGraphComponent() {
  const [users, setUsers] = useState<AdminUserViewDTO[]>([]);

  useEffect(() => {
    AllUserAPI().then((r) => {
      setUsers(r);
    });
  }, []);

  const aggregatedData = users.reduce(
    (acc, user) => {
      const date = user.date_joined.split('T')[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    },
    {} as { [key: string]: number },
  );

  const userDates = Object.keys(aggregatedData).sort();

  let cumulativeCount = 0;
  const numberOfUser = userDates.map((date) => {
    cumulativeCount += aggregatedData[date];
    return cumulativeCount;
  });

  return <GraphsComponent name={t('users')} categories={userDates} data={numberOfUser} />;
}
