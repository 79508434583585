import { useEffect, useState } from 'react';
import { HubWithFlagDTO } from '../../dto';
import { GetHubWithFlags } from '../../apiService';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { HomePageMap } from '../../components/home/HomePageInfo';

export function HomePage() {
  const [hubs, setHubs] = useState<HubWithFlagDTO[]>();

  useEffect(() => {
    GetHubWithFlags().then((res) => {
      setHubs(res);
    });
  }, []);

  if (!hubs) {
    return <LoadingSpinner />;
  }

  return <HomePageMap hubs={hubs} />;
}
