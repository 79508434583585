import Chart from 'react-apexcharts';
import {
  blackColor,
  darkGray,
  dimGray,
  iquColor,
  lightGray,
  mediumGray,
  shadedWhiteColor,
  veryDarkGray,
} from '../../../styles';
import { useColorMode } from '@chakra-ui/react';

interface GraphsComponentInterface {
  name: string;
  categories: string[];
  data: number[];
  graph_type?: 'line' | 'bar';
}

export function GraphsComponent({ name, categories, data, graph_type }: GraphsComponentInterface) {
  const isDarkMode = useColorMode().colorMode === 'dark';
  const state = {
    options: {
      chart: {
        id: name,
        foreColor: shadedWhiteColor,
        toolbar: {
          show: false,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
        background: isDarkMode ? veryDarkGray : shadedWhiteColor,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          style: {
            colors: isDarkMode ? [shadedWhiteColor] : [blackColor],
          },
        },
      },
      grid: {
        borderColor: mediumGray,
        row: {
          colors: isDarkMode ? [dimGray, darkGray] : [shadedWhiteColor, lightGray],
          opacity: 0.9,
        },
      },
      title: {
        text: name,
        style: {
          color: isDarkMode ? shadedWhiteColor : blackColor,
        },
      },
      stroke: {
        show: true,
        colors: [iquColor],
        width: 5,
        dashArray: 0,
        curve: ['smooth'] as ('smooth' | 'straight' | 'stepline' | 'monotoneCubic')[],
      },
      markers: {
        size: 4,
        colors: [iquColor],
        strokeColors: blackColor,
        strokeWidth: 1,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        discrete: [],
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      tooltip: {
        enabled: true,
        theme: isDarkMode ? 'dark' : 'light',
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
        },
        onDatasetHover: {
          highlightDataSeries: true,
        },
        marker: {
          show: true,
        },
        items: {
          display: 'flex',
        },
        fixed: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: name,
        data: data,
      },
    ],
  };

  if (!graph_type) {
    graph_type = 'line';
  }

  return <Chart options={state.options} series={state.series} type={graph_type} />;
}
