import { useEffect, useState } from 'react';
import { FlagDto } from '../../../dto';
import { AllFlags } from '../../../apiService';
import { GraphsComponent } from './GraphsComponent';
import { t } from 'i18next';

export function FlagGraphComponent() {
  const [flags, setFlags] = useState<FlagDto[]>([]);

  useEffect(() => {
    AllFlags().then((r) => {
      setFlags(r.data);
    });
  }, []);

  const fillMissingDates = (data: { [key: string]: number }) => {
    const sortedDates = Object.keys(data).sort();
    const startDate = new Date(sortedDates[0]);
    const endDate = new Date(sortedDates[sortedDates.length - 1]);

    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateStr = d.toISOString().split('T')[0];
      if (!data[dateStr]) {
        data[dateStr] = 0;
      }
    }

    return data;
  };

  const aggregatedData = flags.reduce(
    (acc, flag) => {
      const date = flag.time.split('T')[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    },
    {} as { [key: string]: number },
  );

  const filledData = fillMissingDates(aggregatedData);

  const flagDates = Object.keys(filledData).sort();
  const numberOfFlags = flagDates.map((date) => filledData[date]);

  return <GraphsComponent name={t('flags')} categories={flagDates} data={numberOfFlags} />;
}
