import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { theme } from './theme';
import { AuthProvider } from './components/login/AuthContext';
import * as Sentry from '@sentry/react';
import { I18nextProvider } from 'react-i18next';
import { initializeI18n } from './translations/Translations';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    {localStorage.setItem('chakra-ui-color-mode', theme.config.initialColorMode)}
    <I18nextProvider i18n={initializeI18n()}>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ChakraProvider>
    </I18nextProvider>
  </>,
);

reportWebVitals();
