import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Heading,
  Input,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import { UploadProfilePicture } from '../../apiService';
import { ProfilePicture } from '../../components/user/ProfilePicture';
import { useGlobalUser } from '../../components/user/useContext';
import { t } from 'i18next';

export function Profile() {
  const { user, RefreshUser } = useGlobalUser();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      UploadProfilePicture(selectedFile).then(() => {
        RefreshUser();
      });
    }
  };

  return (
    <Center>
      <Card w={'500px'}>
        <CardHeader>
          <Center>
            <Heading size="md">{t('profile')}</Heading>
            <Box position={'absolute'} top={2} right={5}>
              <ProfilePicture />
            </Box>
          </Center>
        </CardHeader>
        <Divider />

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                {t('username')}
              </Heading>
              <Text pt="2" fontSize="sm">
                {user?.username}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                {t('code')}
              </Heading>
              <Text pt="2" fontSize="sm">
                {user?.code}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                {t('name')}
              </Heading>
              <Text pt="2" fontSize="sm">
                {user?.first_name} {user?.last_name}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                {t('email')}
              </Heading>
              <Text pt="2" fontSize="sm">
                {user?.email}
              </Text>
            </Box>
            <Box mt={4}>
              <Input type="file" onChange={handleFileChange} pt={'6px'} pl={2} />
              <Button onClick={handleUpload} mt={2}>
                {t('upload')}
              </Button>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Center>
  );
}
