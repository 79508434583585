import React, { useEffect, useState } from 'react';
import { HubDTO } from '../../../dto';
import { AllHubs } from '../../../apiService';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { GraphsComponent } from './GraphsComponent';
import { Box, Center } from '@chakra-ui/react';
import { t } from 'i18next';

const getAggregatedDataWithMissingDatesFilled = (items: HubDTO[]) => {
  const aggregatedData: { [key: string]: number } = {};

  items.forEach((item) => {
    const date = item.date_added.split('T')[0];
    aggregatedData[date] = (aggregatedData[date] || 0) + 1;
  });

  const sortedDates = Object.keys(aggregatedData).sort();
  const startDate = new Date(sortedDates[0]);
  const endDate = new Date(sortedDates[sortedDates.length - 1]);

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split('T')[0];
    if (!aggregatedData[dateStr]) {
      aggregatedData[dateStr] = 0;
    }
  }

  return aggregatedData;
};

export const BigHubCounterComponent: React.FC = () => {
  const [hubs, setHubs] = useState<HubDTO[]>([]);

  useEffect(() => {
    setInterval(() => {
      AllHubs().then((r) => {
        setHubs(r);
      });
    }, 10 * 1000);
  }, []);

  if (!hubs.length) return <LoadingSpinner />;

  const filledData = getAggregatedDataWithMissingDatesFilled(hubs);

  const hubDates = Object.keys(filledData).sort();
  let cumulativeCount = 0;
  const numberOfHubs = hubDates.map((date) => {
    cumulativeCount += filledData[date];
    return cumulativeCount;
  });

  return (
    <Center w={'100%'}>
      <Box w={'80%'}>
        <GraphsComponent name={t('cores') + ': ' + hubs.length} categories={hubDates} data={numberOfHubs} />
      </Box>
    </Center>
  );
};
