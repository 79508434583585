export const iquColor = '#548c3d';
export const goodColor = iquColor; //'#3bd000';

export const blackColor = '#000';
export const shadedWhiteColor = '#edf2f7';
export const mediumGray = '#636363';

export const veryDarkGray = '#222';
export const dimGray = '#3C3C3C';
export const darkGray = '#444';
export const lightGray = '#EEEEEE';

export const debugColor = 'gray.500';
export const infoColor = '#C0C0C0';
export const warningColor = '#D8931C';
export const errorColor = '#DC4D23';

export const criticalColor = '#CA0000';

export const urgentColor = '#8B0000';

export const semiTransparentBlack = '#00000066';
export const grayishBlue = '#1a202c';
export const whiteColor = '#FFFFFF';
