import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { GetConfig, GetConfigStatus } from '../../apiService';
import { FaDownload, FaUpload } from 'react-icons/fa6';
import { IoReload } from 'react-icons/io5';
import { configStatusDTO } from '../../dto';
import { t } from 'i18next';

interface UploadConfigInterface {
  hub_id: string;
  handleClick: (data: string) => void;
}
export function UploadConfig({ hub_id, handleClick }: UploadConfigInterface) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [configStatus, setConfigStatus] = useState<configStatusDTO>();
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const refresh = useCallback(() => {
    setRefreshing(true);
    GetConfigStatus(hub_id)
      .then((r: configStatusDTO) => {
        setConfigStatus(r);
        setRefreshing(false);
      })
      .catch(() => {
        setConfigStatus(undefined);
        setRefreshing(false);
      });
  }, [hub_id]);

  useEffect(() => {
    if (isOpen) {
      const intervalId = setInterval(refresh, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isOpen, refresh]);

  async function downloadConfigFile(): Promise<void> {
    try {
      GetConfig(hub_id).then((r) => {
        const url = window.URL.createObjectURL(r.data);
        const link = Object.assign(document.createElement('a'), { href: url, download: 'configs.zip' });
        link.click();
      });
    } catch (error) {
      console.error(t('error_downloading_config_file'), ':', error);
    }
  }

  return (
    <Box>
      <Button
        w={'100%'}
        h={'100%'}
        value={'upload-config'}
        onClick={() => {
          onOpen();
        }}
      >
        {t('upload_config')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{t('config')}</ModalHeader>
          <ModalBody>
            <Text fontSize={'xl'} mb={2} w={'100%'}>
              {t('upload_config')}:
            </Text>
            <Flex justify={'end'} w={'100%'}>
              <IconButton
                aria-label={'Upload'}
                icon={<FaUpload size={20} />}
                w={'100%'}
                onClick={() => {
                  handleClick('upload-config');
                }}
              />
            </Flex>
            <Divider mt={12} mb={8} />
            <Flex w={'100%'}>
              <Text fontSize={'xl'} mb={2} w={'100%'}>
                {t('download_config')}:
              </Text>
              <Flex>
                <IconButton
                  bg={'none'}
                  aria-label={'refresh'}
                  icon={refreshing ? <Spinner /> : <IoReload size={20} />}
                  onClick={() => {
                    refresh();
                  }}
                />
              </Flex>
            </Flex>
            {configStatus ? (
              <Card p={2} mb={6}>
                <Text fontSize={'lg'}>{t('configs_zip')}</Text>
                <Flex align={'center'}>
                  <Text w={'70%'}>
                    {t('uploaded_on')}
                    {': '}
                    {new Date(configStatus['uploaded_on']).toLocaleString(undefined, {
                      hour12: false,
                    })}
                  </Text>
                  <Flex justify={'end'} w={'30%'} align={'center'}>
                    <IconButton
                      aria-label={'Download'}
                      icon={<FaDownload size={20} />}
                      onClick={() => {
                        downloadConfigFile();
                      }}
                    />
                  </Flex>
                </Flex>
              </Card>
            ) : (
              <Text>{t('no_config_found')}</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
