import { AbsoluteCenter, Center, Flex, Image } from '@chakra-ui/react';
import IQULogo from '../../assets/images/iQU_transparent.png';

export function LoadingNoSpinner() {
  return (
    <Flex height="90vh">
      <AbsoluteCenter>
        <Center>
          <Image boxSize="30%" src={IQULogo} />
        </Center>
      </AbsoluteCenter>
    </Flex>
  );
}
