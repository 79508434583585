import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { GetVersion, Login, TwoFactorAuthAPI } from '../../apiService';
import { useGlobalAuth } from '../../components/login/AuthContext';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { t } from 'i18next';

export function TwoFactorPage() {
  const [pin, setPin] = useState<string>('');
  const { setTwoFactor, Logout } = useGlobalAuth();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleChange = () => {
    setIsLoading(true);
    TwoFactorAuthAPI(pin)
      .then((res) => {
        if (res === 200) {
          setTwoFactor(true);
        }
        setIsLoading(false);
      })
      .catch(() => {
        toast({
          title: t('invalid_code'),
          description: t('wrong_two_factor_code'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  if (pin.length == 6) {
    handleChange();
    setPin('');
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Center minH="100vh">
      <Card borderWidth={1} borderColor={'gray'}>
        <CardHeader>
          <Heading>{t('enter_2fa_code')}</Heading>
        </CardHeader>
        <Divider />
        <CardBody>
          <HStack>
            <PinInput
              onChange={(e) => {
                setPin(e);
              }}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        </CardBody>
        <CardFooter>
          <Button onClick={Logout}>{t('re_login')}</Button>
        </CardFooter>
      </Card>
    </Center>
  );
}

export function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const { loggedIn, setAccessToken } = useGlobalAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [version, setVersion] = useState('Unknown version');

  useEffect(() => {
    GetVersion().then((r) => {
      setVersion(r['version']);
    });
  }, []);

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await Login(username, password);
      const refreshToken = response?.refresh;
      if (refreshToken) {
        const accessToken = response?.access;
        if (accessToken) {
          setAccessToken(accessToken);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      toast({
        title: t('login_failed'),
        description: t('invalid_username_or_password'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Center minH="100vh">
      <VStack spacing={4} align="center" w="300px" p="20px" borderRadius="10px">
        <Heading size="lg">{t('login')}</Heading>
        <FormControl>
          <FormLabel>{t('username')}</FormLabel>
          <Input
            type="text"
            placeholder={t('enter_your_username')}
            value={username}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t('password')}</FormLabel>
          <Input
            type="password"
            placeholder={t('enter_your_password')}
            value={password}
            onKeyDown={handleKeyDown}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button onClick={handleLogin}>{t('log_in')}</Button>
        <Text>{loggedIn ? t('redirecting') + ' ...' : ''}</Text>
        <Box top={'93%'} position={'absolute'}>
          <Center>
            <VStack>
              <Text as="a" href={'https://www.iqu.no'}>
                © {new Date().getFullYear()} iQU AS
              </Text>
              <Text>{version}</Text>
            </VStack>
          </Center>
        </Box>
      </VStack>
    </Center>
  );
}
