import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Input,
  SimpleGrid,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { DeleteUserInvite, GetUserInvites, SendUserInvite, UpdateUserInvite } from '../../../apiService';
import { UserInvitesDto } from '../../../dto';
import { t } from 'i18next';

interface UserTableInterface {
  invites: UserInvitesDto[];
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: boolean;
}

function UserTable({ invites, refresh, setRefresh }: UserTableInterface) {
  return (
    <TableContainer>
      <Table variant="striped" colorScheme="teal" size={'xs'}>
        <TableCaption>
          <Button
            onClick={() => {
              setRefresh(!refresh);
            }}
          >
            {t('refresh')}
          </Button>
        </TableCaption>
        <Thead>
          <Tr>
            <Th>{t('delete')}</Th>
            <Th>{t('email')}</Th>
            <Th>{t('invited_by')}</Th>
            <Th>{t('created_time')}</Th>
            <Th>{t('expire_time')}</Th>
            <Th>{t('add_time')}</Th>
            <Th>{t('add_time')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invites.map((invite) => {
            return (
              <Tr key={invite.email}>
                <Td>
                  <Button
                    onClick={() => {
                      DeleteUserInvite(invite.email);
                      setRefresh(!refresh);
                    }}
                  >
                    {t('delete_uppercasede')}
                  </Button>
                </Td>
                <Td>{invite.email}</Td>
                <Td>{invite.invited_by__username}</Td>
                <Td>{new Date(invite.created_time).toLocaleString('en-US', { hour12: false })}</Td>
                <Td>{new Date(invite.expire_datetime).toLocaleString('en-US', { hour12: false })}</Td>
                <Td>
                  <Button
                    onClick={() => {
                      UpdateUserInvite(invite.email, 1);
                      setRefresh(!refresh);
                    }}
                  >
                    {t('add_+1_hour')}
                  </Button>
                </Td>
                <Td>
                  <Button
                    onClick={() => {
                      UpdateUserInvite(invite.email, 3);
                      setRefresh(!refresh);
                    }}
                  >
                    {t('add_+3_hour')}
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export function AddUser() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const toast = useToast();
  const [invites, setInvites] = useState<UserInvitesDto[]>([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    GetUserInvites().then((res) => setInvites(res));
  }, [refresh]);

  const sendInvite = () => {
    if (email !== confirmEmail || !RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$').test(email)) {
      toast({
        title: t('emails_do_not_match_or_are_invalid'),
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    } else {
      SendUserInvite(email).then((res) => {
        if (res === 201) {
          toast({
            title: t('invite_sent'),
            description: t('invite_sent_to') + ' ' + email,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          setEmail('');
          setConfirmEmail('');
        } else {
          toast({
            title: t('something_went_wrong'),
            description: t('failed_sending_invite_to') + ':' + email,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      });
    }
    setRefresh(!refresh);
  };

  return (
    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
      <Card>
        <CardHeader>
          <Center>
            <Text>{t('add_user')}</Text>
          </Center>
        </CardHeader>
        <CardBody>
          <Text>{t('email')}:</Text>
          <Input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Text>{t('confirm_email')}:</Text>
          <Input
            value={confirmEmail}
            onChange={(e) => {
              setConfirmEmail(e.target.value);
            }}
          />
        </CardBody>
        <CardFooter>
          <Button onClick={sendInvite}>{t('send_invite')}</Button>
        </CardFooter>
      </Card>
      <UserTable invites={invites} refresh={refresh} setRefresh={setRefresh} />
    </SimpleGrid>
  );
}
