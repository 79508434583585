import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { HubDTO } from '../../dto';
import { Link } from 'react-router-dom';
import { StatusHubIcon } from '../TimeUtils/TimeUtils';
import { t } from 'i18next';

interface HubCardProp {
  hub: HubDTO;
}

export function HubCard({ hub }: HubCardProp) {
  return (
    <Card height={'100%'} width={'100%'} rounded={'lg'} p={6}>
      <CardHeader>
        <Center>
          <StatusHubIcon hub={hub} />
          <VStack>
            <Heading fontSize={'xl'} fontFamily={'body'}>
              {hub.hub_name}
            </Heading>
            <Text fontSize={'xs'} mb={4}>
              {hub.hub_id}
            </Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider />
      <CardBody>
        <Center>
          <HStack>
            <Badge px={2} py={1} fontWeight={'400'}>
              {hub.city}
            </Badge>
            <Badge px={2} py={1} fontWeight={'400'}>
              {hub.post_code}
            </Badge>
            <Badge px={2} py={1} fontWeight={'400'}>
              {hub.date_added}
            </Badge>
          </HStack>
        </Center>
      </CardBody>
      <Center>
        <Flex width={'100%'} gap={8}>
          <Box width={'100%'}>
            <Link to={'/core/' + hub.hub_id}>
              <Button width={'100%'} fontSize={'sm'} rounded={'full'}>
                {t('manage')}
              </Button>
            </Link>
          </Box>
          <Button width={'100%'} fontSize={'sm'} rounded={'full'}>
            {t('info')}
          </Button>
        </Flex>
      </Center>
    </Card>
  );
}
