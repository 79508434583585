import { AbsoluteCenter, Center, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import IQULogo from '../../assets/images/iQU_transparent.png';
import { t } from 'i18next';

export function NotFound404() {
  return (
    <Flex height="90vh">
      <AbsoluteCenter>
        <Center>
          <Heading>{t('404_not_found')}</Heading>
        </Center>
        <Center>
          <Image boxSize="30%" src={IQULogo} />
        </Center>
        <Center>
          <Flex gap={'10px'}>
            <Text color={'#4e4ec5'} as={'ins'}>
              <Link to={'/'}>{t('home')}</Link>
            </Text>
          </Flex>
        </Center>
      </AbsoluteCenter>
    </Flex>
  );
}
