import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdDeleteOutline } from 'react-icons/md';
import { t } from 'i18next';

interface SubmitFilesProps {
  setFiles: (data: Record<string, File>) => void;
  doneClicked?: () => void;
  directoryHelp?: string;
}

export function SubmitFiles({ setFiles, doneClicked, directoryHelp }: SubmitFilesProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFiles, setSelectedFiles] = useState<Record<string, File>>({});
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (name: string, file: File) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [name]: file,
    }));
  };

  const handleKeyChange = (oldKey: string, newKey: string) => {
    if (oldKey !== newKey) {
      setSelectedFiles((prevSelectedFiles) => {
        const updatedFiles = { ...prevSelectedFiles };
        updatedFiles[newKey] = updatedFiles[oldKey];
        delete updatedFiles[oldKey];
        return updatedFiles;
      });
    }
  };

  return (
    <Box>
      <Button w={'100%'} onClick={onOpen}>
        {t('new_config')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{t('select_files')}</ModalHeader>
          <ModalBody>
            {Object.keys(selectedFiles).length === 0 && (
              <Text w={'100%'} align={'center'} opacity={'50%'}>
                {t('no_files_selected')}.
              </Text>
            )}
            {Object.entries(selectedFiles).map(([key, file]) => (
              <Card key={key} m={2} p={2} flexDirection={'row'}>
                <Flex w={'100%'} align={'center'}>
                  {directoryHelp && (
                    <Box p={'2'} borderRadius={'10px'}>
                      {directoryHelp}
                    </Box>
                  )}
                  <Input
                    defaultValue={key}
                    onBlur={(e) => handleKeyChange(key, e.target.value.replace(/\.{2}/g, ''))}
                  />
                </Flex>
                <Flex w={'100%'} justify={'end'} align={'center'}>
                  <Text opacity={'50%'}>{file.name}</Text>
                  <IconButton
                    color={'red'}
                    bg={'none'}
                    aria-label={'Delete'}
                    icon={<MdDeleteOutline size={30} />}
                    onClick={() => {
                      setSelectedFiles((prevState) => {
                        const newState = { ...prevState };
                        delete newState[key];
                        return newState;
                      });
                    }}
                  />
                </Flex>
              </Card>
            ))}
            <Flex w={'100%'} p={2} gap={2} mt={4}>
              <Flex w={'100%'} justify={'end'} align={'center'}>
                <Button w={24} onClick={() => fileInputRef.current?.click()}>
                  {t('select_file')}
                </Button>
                <input
                  ref={fileInputRef}
                  type={'file'}
                  onChange={(e) => {
                    if (e.target.files != null) {
                      handleFileChange(e.target.files[0].name, e.target.files[0]);
                    }
                  }}
                  style={{
                    opacity: 0,
                    position: 'absolute',
                    zIndex: 2,
                    fontSize: 24,
                    width: 100,
                    pointerEvents: 'none',
                  }}
                />
              </Flex>
              <Button
                w={24}
                borderWidth={2}
                borderColor={'iquColor'}
                color={'iquColor'}
                onClick={() => {
                  setFiles(selectedFiles);
                  doneClicked?.();
                  setSelectedFiles({});
                  onClose();
                }}
              >
                {t('done')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
