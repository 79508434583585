import { useEffect, useState } from 'react';
import { Box, Divider, Flex, Heading } from '@chakra-ui/react';
import { GetFlagAPI, HubAPI } from '../../apiService';
import { FlagDto, HubDTO } from '../../dto';
import { useParams } from 'react-router-dom';
import { FlagInfo } from '../../components/flags/FlagInfo';
import { FlagControls } from '../../components/flags/FlagControls';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { t } from 'i18next';

export function ManageFlagPage() {
  const [flag, setFlag] = useState<FlagDto>();
  const [hub, setHub] = useState<HubDTO>();
  const { hub_id, flag_id } = useParams();

  useEffect(() => {
    if (hub_id && flag_id) {
      HubAPI(hub_id).then((hubRes) => {
        setHub(hubRes);
      });
      GetFlagAPI(hub_id, flag_id).then((flagRes) => setFlag(flagRes));
    }
  }, [flag_id, hub?.user_id, hub_id, flag?.is_active]);

  if (!flag || !hub) {
    return <LoadingSpinner />;
  }

  return (
    <Box h={'80vh'}>
      <Box>
        <Heading>
          {t('flag')} {flag_id}
        </Heading>
        <Divider />
      </Box>
      <Flex gap={2} mt={2} h={'100%'} flexDirection={{ base: 'column', md: 'row' }}>
        <Box w={{ base: '100%', md: '70%' }}>
          <FlagInfo flag={flag} />
        </Box>
        <Box w={{ base: '100%', md: '30%' }}>
          <FlagControls
            flag={flag}
            hub_id={hub?.hub_id}
            setFlag={setFlag as (data: FlagDto | ((prevFlag: FlagDto) => FlagDto)) => void}
          />
        </Box>
      </Flex>
    </Box>
  );
}
