import { useEffect, useState } from 'react';
import { FlagDto } from '../../../dto';
import { AllFlags } from '../../../apiService';
import { GraphsComponent } from './GraphsComponent';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { t } from 'i18next';

export function TotalFlagsComponent() {
  const [flags, setFlags] = useState<FlagDto[]>([]);

  useEffect(() => {
    AllFlags().then((r) => {
      setFlags(r.data.filter((flag) => flag.is_active));
    });
  }, []);

  if (!flags) {
    return <LoadingSpinner />;
  }

  const getAggregatedDataWithMissingDatesFilled = (items: FlagDto[]) => {
    const aggregatedData: { [key: string]: number } = {};

    items.forEach((item) => {
      const date = item.time.split('T')[0];
      aggregatedData[date] = (aggregatedData[date] || 0) + 1;
    });

    const sortedDates = Object.keys(aggregatedData).sort();
    const startDate = new Date(sortedDates[0]);
    const endDate = new Date(sortedDates[sortedDates.length - 1]);

    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateStr = d.toISOString().split('T')[0];
      if (!aggregatedData[dateStr]) {
        aggregatedData[dateStr] = 0;
      }
    }

    return aggregatedData;
  };

  const filledData = getAggregatedDataWithMissingDatesFilled(flags);

  const hubDates = Object.keys(filledData).sort();
  let cumulativeCount = 0;
  const numberOfHubs = hubDates.map((date) => {
    cumulativeCount += filledData[date];
    return cumulativeCount;
  });

  return <GraphsComponent name={t('total_active_flags')} categories={hubDates} data={numberOfHubs} />;
}
