import { Box, Icon, useColorMode } from '@chakra-ui/react';
import { MdNotificationAdd, MdNotifications } from 'react-icons/md';

interface BellIconInterface {
  shouldAnimate: boolean;
}

export function BellIcon({ shouldAnimate }: BellIconInterface) {
  const isDarkMode = useColorMode().colorMode === 'dark';

  return (
    <Box>
      <Icon
        as={shouldAnimate ? MdNotificationAdd : MdNotifications}
        color={isDarkMode ? 'white' : 'black'}
        boxSize={19}
        mt={1}
      />
    </Box>
  );
}
