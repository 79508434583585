import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Switch } from '@chakra-ui/react';
import { iquColor } from '../../styles';
import { t } from 'i18next';

interface SortMenu {
  setFilterInactive: (filterInactive: boolean) => void;
  filterInactive: boolean;
  setSortingMode: (sortingMode: string) => void;
}

export function SortMenu({ setFilterInactive, filterInactive, setSortingMode }: SortMenu) {
  return (
    <Box>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} borderColor={iquColor} borderWidth={2} color={iquColor} variant="outline" ml={'2.5%'}>
          {t('filter')}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => setFilterInactive(!filterInactive)}>
            <Switch isChecked={filterInactive} style={{ pointerEvents: 'none' }} mr={4} /> Inactive
          </MenuItem>
        </MenuList>
      </Menu>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          borderColor={iquColor}
          borderWidth={2}
          color={iquColor}
          variant="outline"
          ml={'0.5%'}
          fontSize="md"
          fontWeight="semibold"
        >
          {t('sort')}
        </MenuButton>
        <MenuList borderRadius="lg" boxShadow="md" p={2} minWidth="150px">
          <MenuItem onClick={() => setSortingMode('-time')} mt={2}>
            {t('newest')}
          </MenuItem>
          <MenuItem onClick={() => setSortingMode('id')} mt={2}>
            {t('id')}
          </MenuItem>
          <MenuItem onClick={() => setSortingMode('level')} mt={2}>
            {t('level')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
