import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Navigate, useParams } from 'react-router-dom';
import { CreateUserAPI, GetCreateUser } from '../../../apiService';
import IQULogo from '../../../assets/images/iQU_transparent.png';
import { LoadingNoSpinner } from '../../../components/loading/LoadingNoSpinner';
import { t } from 'i18next';

export function CreateProfile() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState<string | null>(null);
  const { inviteId } = useParams();
  const [show, setShow] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [validPhone, setValidPhone] = useState(false);
  const [validUserName, setValidUserName] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isDone, setIsDone] = useState(false);

  const handleClick = () => setShow(!show);

  useEffect(() => {
    if (inviteId) {
      GetCreateUser(inviteId).then((res) => {
        setEmail(res.email);
      });
    }
  }, [inviteId]);

  useEffect(() => {
    if (RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$').test(password)) {
      setValidPassword(true);
    }
    if (password === confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (RegExp('^\\+?[1-9][0-9]{7,14}$').test(phoneNumber)) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (RegExp('^[A-Za-z]{6,24}$').test(username)) {
      setValidUserName(true);
    } else {
      setValidUserName(false);
    }
  }, [username]);

  if (!email || !inviteId) {
    return <LoadingNoSpinner />;
  }

  const handleCreateUser = () => {
    CreateUserAPI(inviteId, username, phoneNumber, password, firstName, lastName).then((r) => {
      if (r == 201) {
        setIsDone(true);
      } else console.log(t('something_did_work_status') + ': ' + r.toString());
    });
  };

  if (isDone) {
    return <Navigate replace to="/" />;
  }

  return (
    <Center>
      <Card w={'100%'}>
        <CardHeader>
          <Center>
            <VStack>
              <Heading>{t('create_user')}</Heading>
              <Image boxSize="15%" src={IQULogo} />
            </VStack>
          </Center>
        </CardHeader>
        <CardBody>
          <FormControl isRequired>
            <FormLabel>{t('username')}</FormLabel>
            <Input
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            {validUserName ? null : <Text color={'red'}>{t('username_error')}</Text>}
            <SimpleGrid columns={2}>
              <FormLabel>{t('first_name')}</FormLabel>
              <FormLabel>{t('last_name')}</FormLabel>

              <Input
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <Input
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2}>
              <FormLabel>{t('phone_number')}</FormLabel>
              <FormLabel>{t('email')}</FormLabel>
              <Box>
                <Input
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
                {validPhone ? null : <Text color={'red'}>{t('phone_number_error')}</Text>}
              </Box>
              <Input value={email} disabled={true} />
            </SimpleGrid>
            <FormLabel>{t('password')}</FormLabel>

            <InputGroup size="md">
              <Input
                pr="4.5rem"
                value={password}
                type={show ? 'text' : 'password'}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder={t('enter_password')}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? t('hide') : t('show')}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Text color={'red'}>{validPassword ? null : t('password_requirements')}</Text>
            <FormLabel>{t('confirm_password')}</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                value={confirmPassword}
                type={show ? 'text' : 'password'}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                placeholder={t('confirm_password')}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? t('hide') : t('show')}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </CardBody>
        <CardFooter>
          <Flex width={'100%'}>
            <Box>
              <Text color={'red'}>{passwordMatch ? null : t('passwords_does_not_match')}</Text>
            </Box>
            <Spacer />
            {passwordMatch && validPassword && validPhone && validUserName ? (
              <Button onClick={handleCreateUser}>{t('submit')}</Button>
            ) : (
              <Button isDisabled={true}>{t('submit')}</Button>
            )}
          </Flex>
        </CardFooter>
      </Card>
    </Center>
  );
}
