import { HubWithFlagDTO } from '../../../dto';
import { ReactElement } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BiErrorAlt } from 'react-icons/bi';
import { criticalColor, iquColor, warningColor } from '../../../styles';
import { Link } from 'react-router-dom';
import { IsOnline } from '../../TimeUtils/TimeUtils';
import { t } from 'i18next';

interface SmallHubCardInterface {
  hub: HubWithFlagDTO;
  setSelectedHubMarker?: (hub: HubWithFlagDTO) => void;
}

interface SmallHubFlagCardInterface {
  stat: number | undefined;
  text: string;
  icon: ReactElement;
}

function SmallHubFlagCard({ stat, text, icon }: SmallHubFlagCardInterface) {
  if (!stat) {
    stat = 0;
  }
  return (
    <HStack>
      {icon}
      <Heading size={'md'}>{text}</Heading>
      {icon}
    </HStack>
  );
}

export function SmallHubCard({ hub, setSelectedHubMarker }: SmallHubCardInterface) {
  const hasProblem = !hub.flags?.length;

  const HandleClick = () => {
    if (setSelectedHubMarker) {
      setSelectedHubMarker(hub);
    }
  };

  const ColorToUse = () => {
    if (!hasProblem && IsOnline(hub)) {
      return warningColor;
    }
    if (!IsOnline(hub)) {
      return criticalColor;
    }
    return iquColor;
  };

  return (
    <Card
      onClick={HandleClick}
      rounded={'lg'}
      textAlign={'center'}
      borderTop="2px"
      borderColor={ColorToUse()}
      width={'100%'}
      height={'100%'}
    >
      <CardHeader>
        <Center>
          <VStack>
            <Heading size={'md'}>{hub.hub_name}</Heading>

            <Text as={'u'} fontSize="xs">
              {hub.hub_id}
            </Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider />
      <CardBody>
        <Center>
          <VStack>
            <SmallHubFlagCard
              stat={hub.flags?.length}
              text={hasProblem ? t('no_issues') : t('problem_s')}
              icon={<BiErrorAlt color={ColorToUse()} />}
            />
            {hasProblem ? null : (
              <Text fontSize={'s'}>
                {hub.flags?.length} {t('active_flags_lowercase')}
              </Text>
            )}
          </VStack>
        </Center>
      </CardBody>
      <Divider />
      <CardFooter>
        <Center>
          <Link to={'/core/' + hub.hub_id}>
            <Button>{t('manage')}</Button>
          </Link>
        </Center>
      </CardFooter>
    </Card>
  );
}
