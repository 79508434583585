import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GrLanguage } from 'react-icons/gr';
import Cookies from 'js-cookie';

export function LanguageSelector() {
  const { i18n } = useTranslation();
  const changeLanguage = (lng: string | string[]) => {
    if (typeof lng === 'string') {
      i18n.changeLanguage(lng);
      Cookies.set('lng', lng, { expires: 365 });
    }
  };
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} bg={'none'}>
        <GrLanguage />
      </MenuButton>
      <MenuList minWidth="240px" zIndex={999}>
        <MenuOptionGroup defaultValue={i18n.language} type="radio" onChange={changeLanguage}>
          <MenuItemOption value="en">English</MenuItemOption>
          <MenuItemOption value="no">Norsk</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
