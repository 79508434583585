import { useEffect, useState } from 'react';
import { AdminUserViewDTO } from '../../../dto';
import { AllUserAPI } from '../../../apiService';
import { UserListTable } from '../../../components/admin/user/UserListTable';
import { Box, Center } from '@chakra-ui/react';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';

export function UserView() {
  const [users, setUsers] = useState<[AdminUserViewDTO]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    AllUserAPI().then((res) => {
      setUsers(res);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!users) {
    return <LoadingSpinner />;
  }
  return (
    <Center>
      <Box w={'80%'}>
        <UserListTable users={users} />
      </Box>
    </Center>
  );
}
