import { Badge, Button, Card, Divider, Flex, Heading, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { FlagDataDTO, FlagDto } from '../../dto';
import { Link } from 'react-router-dom';
import { DeactivateFlag } from '../../apiService';
import { criticalColor, errorColor, infoColor, semiTransparentBlack, urgentColor, warningColor } from '../../styles';
import { PiWarning } from 'react-icons/pi';
import { t } from 'i18next';

interface FlagCardInterface {
  setFlagData: (flags: FlagDataDTO) => void;
  flagData: FlagDataDTO;
  flag: FlagDto;
}

export const FlagCard = ({ flag, setFlagData, flagData }: FlagCardInterface) => {
  let iconColor;
  switch (flag.level) {
    case 'info':
      iconColor = infoColor;
      break;
    case 'warning':
      iconColor = warningColor;
      break;
    case 'error':
      iconColor = errorColor;
      break;
    case 'critical':
      iconColor = criticalColor;
      break;
    case 'urgent':
      iconColor = urgentColor;
      break;
  }
  if (!flag.is_active) {
    iconColor = semiTransparentBlack;
  }

  const updateFilteredFlags = (deactivatedFlag: FlagDto) => {
    setFlagData({
      data: flagData.data.map((f) => (f.id === deactivatedFlag.id ? { ...f, is_active: false } : f)),
      meta: flagData.meta,
    });
  };

  return (
    <Card w={'404px'} rounded={'lg'} textAlign={'center'} display="flex" flexDirection="column">
      <Flex justifyContent={'end'}>
        <Tooltip
          label={`${flag.is_active ? '' : t('inactive_uppercase')} ${flag.level.toUpperCase()} at ${new Date(
            flag.time,
          ).toLocaleString(undefined, { hour12: false })}`}
        >
          <IconButton fontSize="25" aria-label="Flag level icon" backgroundColor={iconColor}>
            <PiWarning color={'white'} />
          </IconButton>
        </Tooltip>
      </Flex>
      <Flex flexDirection={'column'} paddingInline={6} pb={6}>
        <Heading fontSize={'xl'} fontFamily={'body'} mb={2}>
          Flag {flag.id}
        </Heading>
        <Flex justifyContent={'center'} flexDirection={'column'} mb={4}>
          <Text fontSize={'xs'} mb={4}>
            {flag.hub}
          </Text>
          <Divider mb={4} />
          <Badge
            p={2}
            fontWeight={'400'}
            whiteSpace="normal"
            overflow="hidden"
            textOverflow="ellipsis"
            rounded={4}
            h={'66px'}
          >
            {flag.message}
          </Badge>
        </Flex>
        <Flex justifyContent={'center'} align={'end'} gap={8}>
          <Link to={'/core/' + flag.hub + '/flag/' + flag.id} style={{ width: '35%' }}>
            <Button fontSize={'sm'} rounded={'full'} width={'100%'} mt={2} mb={2}>
              {t('manage')}
            </Button>
          </Link>
          {flag.is_active && (
            <Button
              fontSize={'sm'}
              rounded={'full'}
              width={'35%'}
              mt={2}
              mb={2}
              onClick={() => {
                if (flag.is_active) {
                  DeactivateFlag(flag.hub, flag.id);
                  updateFilteredFlags(flag);
                }
              }}
            >
              {t('deactivate')}
            </Button>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
