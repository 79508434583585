import { useEffect, useState } from 'react';
import { FlagDto, HubDTO } from '../../dto';
import { GetHubFlagsAPI } from '../../apiService';
import { Box, Card, CardHeader, Divider, Stat, StatGroup, StatLabel, StatNumber, Text } from '@chakra-ui/react';
import { SmallFlagCard } from './SmallFlagCard';
import { t } from 'i18next';

interface HubBoxCardInterface {
  hub: HubDTO;
}

export function FlagBox({ hub }: HubBoxCardInterface) {
  const [flags, setFlags] = useState<FlagDto[]>();

  useEffect(() => {
    GetHubFlagsAPI(hub.hub_id).then((res) => setFlags(res as unknown as FlagDto[]));
  }, [hub.hub_id]);

  if (!flags) {
    return (
      <Box>
        <Text>{t('no_flags_or_warnings')}</Text>
      </Box>
    );
  }
  const warnings = flags.filter((flag) => flag.level === '2' || flag.level === 'warning').length;
  const errors = flags.filter((flag) => flag.level === '1' || flag.level === 'error').length;
  const critical = flags.filter((flag) => flag.level === '0' || flag.level === 'critical').length;

  return (
    <Box>
      <Card>
        <CardHeader>
          <StatGroup>
            <Stat>
              <StatLabel>{t('open_flags')}</StatLabel>
              <StatNumber>{flags.length}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>{t('critical')}</StatLabel>
              <StatNumber>{critical}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>{t('error')}</StatLabel>
              <StatNumber>{errors}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>{t('warning')}</StatLabel>
              <StatNumber>{warnings}</StatNumber>
            </Stat>
          </StatGroup>
        </CardHeader>
        <Divider />
        <Box
          overflowY="auto"
          maxHeight="35vh"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'gray',
              borderRadius: '24px',
            },
          }}
        >
          {flags.map((flag) => (
            <SmallFlagCard key={flag.id + '_flags_card'} flag={flag} />
          ))}
        </Box>
      </Card>
    </Box>
  );
}
