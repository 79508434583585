import React, { createContext, useContext, useEffect, useState } from 'react';
import { GetProfilePicture, UserAPI } from '../../apiService';
import { UserDTO } from '../../dto';
import { t } from 'i18next';

type UseUserReturnType = {
  user: UserDTO;
  RefreshUser: () => void;
  profilePic: string | null;
};

const useUser = (): UseUserReturnType => {
  const [user, setUser] = useState<UserDTO>();
  const [profilePic, setProfilePic] = useState<string | null>(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    UserAPI().then((res) => {
      setUser(res);
    });
    GetProfilePicture().then(setProfilePic);
  }, [refresh]);

  const Refresh = () => {
    setRefresh(!refresh);
  };

  return {
    user: user!,
    RefreshUser: Refresh,
    profilePic: profilePic,
  };
};

interface AuthContextProps extends UseUserReturnType {}

const userContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useUser();

  return <userContext.Provider value={auth}>{children}</userContext.Provider>;
};

export const useGlobalUser = () => {
  const context = useContext(userContext);
  if (!context) {
    throw new Error(t('useglobalguth_must_be_used_within_an_guthprovider'));
  }
  return context;
};
