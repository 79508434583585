import { ReactNode } from 'react';
import { Box, Flex, Stat, StatLabel, StatNumber, useColorModeValue } from '@chakra-ui/react';

interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color?: string;
}

export function HubStatsCard(props: StatsCardProps) {
  const { title, stat, icon, color } = props;
  return (
    <Stat px={{ base: 2, md: 4 }} py={'1'} borderColor={useColorModeValue('gray.800', 'gray.500')} rounded={'lg'}>
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber color={color} fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box my={'auto'} color={useColorModeValue('gray.800', 'gray.200')} alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}
