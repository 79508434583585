import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { HubDTO } from '../../../dto';
import { SendHubCommand, UpdateHub } from '../../../apiService';
import React, { useState } from 'react';
import { IsOnline } from '../../TimeUtils/TimeUtils';
import { SubmitFiles } from '../SubmitFiles';
import { UploadConfig } from '../UploadConfig';
import { PopoverComponent } from './Popover';
import { iquColor } from '../../../styles';
import { IoMdHelpCircle } from 'react-icons/io';
import { CapitalizeAndFormatWord } from '../../utils/Utils';
import { t } from 'i18next';
import { UpdateMenuModal } from '../../update/UpdateMenuModal';

interface HubBoxCardInterface {
  hub: HubDTO;
}
interface AlertDialogInterface {
  isOpen: boolean;
  cancelRef: React.RefObject<never>;
  onClose: () => void;
  sendCommand: (command: string) => void;
  command: string;
}
function SubmitCommandAlert({ isOpen, cancelRef, onClose, command, sendCommand }: AlertDialogInterface) {
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {CapitalizeAndFormatWord(command)}
          </AlertDialogHeader>
          <AlertDialogBody>{t('are_you_sure')}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onClose();
                sendCommand(command);
              }}
              ml={3}
            >
              {CapitalizeAndFormatWord(command)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
export function HubInfoCard({ hub }: HubBoxCardInterface) {
  const allowed_commands = [
    'reboot',
    'restart',
    'start',
    'stop',
    'new-config',
    'upload-config',
    'destruct',
    'update',
    'force-update',
    'new-tag',
  ];
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [command, setCommand] = useState('');
  const [files, setFiles] = useState<Record<string, File>>({});

  const sendCommand = () => {
    SendHubCommand(hub.hub_id, command, files);
    toast({
      title: command + ' ' + t('is_applied_lowercase'),
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    setCommand('');
    setFiles({});
  };
  const handleAuthentication = () => {
    if (!hub.is_authenticated) {
      UpdateHub(hub.hub_id, { is_authenticated: true });
    }
  };
  const handleClick = (_command: string) => {
    if (!(allowed_commands.indexOf(_command.split('?')[0]) > -1)) {
      toast({
        title: t('wrong_command'),
        description: _command + ' ' + t('is_not_valid_command_lowercase') + '.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      setCommand(_command);
      onOpen();
    }
  };
  return (
    <Card width={'100%'}>
      <CardHeader>
        <Center>
          <VStack>
            <Heading as={'h1'}>{t('info_uppercase')}</Heading>
            <Text size={'s'}>{IsOnline(hub) ? t('online') : t('offline')}</Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider paddingTop={'2px'} paddingBottom={'2px'} />
      <CardBody>
        <Text>
          {t('name')}: {hub.hub_name}
        </Text>
        <Text>
          {t('ip')}: {hub.ipaddress}
        </Text>
        <Text>
          {t('address')}: {hub.address}, {hub.post_code}, {hub.city}
        </Text>
        {hub.is_authenticated ? (
          <Text>
            {t('authenticated')}: {t('yes')}
          </Text>
        ) : (
          <Button onClick={handleAuthentication}>{t('authenticate')}</Button>
        )}
        <Divider paddingTop={'10px'} paddingBottom={'10px'} />
        <SubmitCommandAlert
          isOpen={isOpen}
          cancelRef={cancelRef as unknown as React.RefObject<never>}
          onClose={onClose}
          sendCommand={sendCommand}
          command={command}
        />
        <Flex w={'100%'} justifyContent={'center'} mt={4} gap={4}>
          <Heading>{t('add_task_uppercase')}</Heading>
          <PopoverComponent
            PopoverBodyText={t('add_task_info')}
            PopoverHeaderText={t('add_task')}
            PopoverTriggerComponent={
              <IconButton
                aria-label={'Add task info'}
                icon={<IoMdHelpCircle size={'32px'} />}
                bg={'none'}
                color={iquColor}
              />
            }
          />
        </Flex>
        <SimpleGrid minChildWidth="40%" spacing="20px" paddingTop={'20px'}>
          <Button
            value={'restart'}
            onClick={() => {
              handleClick('restart');
            }}
          >
            {t('restart')}
          </Button>
          <Button
            onClick={() => {
              handleClick('reboot');
            }}
            value={'reboot'}
          >
            {t('reboot')}
          </Button>
          <Button
            onClick={() => {
              handleClick('start');
            }}
            value={'start'}
          >
            {t('start')}
          </Button>
          <Button
            onClick={() => {
              handleClick('stop');
            }}
            value={'stop'}
          >
            {t('stop')}
          </Button>
          <UpdateMenuModal hub_id={hub.hub_id} handleClick={handleClick} />
          <SubmitFiles
            directoryHelp={'configs/'}
            setFiles={setFiles}
            doneClicked={() => {
              handleClick('new-config');
            }}
          />
          <UploadConfig hub_id={hub.hub_id} handleClick={handleClick} />
          <Button
            onClick={() => {
              handleClick('destruct');
            }}
            value={'destruct'}
          >
            {t('self_destruct')}
          </Button>
        </SimpleGrid>
        <Divider paddingTop={'10px'} paddingBottom={'10px'} />
      </CardBody>
    </Card>
  );
}
