import { Box, Card, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { HubDTO } from '../../../dto';
import { HubAPI } from '../../../apiService';
import { useParams } from 'react-router-dom';
import { SingleHubMap } from '../../../components/hub/SingleHubMap';
import { FlagBox } from '../../../components/flags/FlagBox';
import { HubInfoCard } from '../../../components/hub/info/HubInfoCard';
import { AssignToHub } from '../../../components/admin/AssignToHub';
import { t } from 'i18next';

interface HubBoxCardInterface {
  hub: HubDTO;
}

function HubBoxCard({ hub }: HubBoxCardInterface) {
  return (
    <Grid
      gridTemplateColumns={{
        base: '100%',
        md: '3fr 5fr 4fr',
        sm: '3fr 5fr 4fr',
      }}
      gridTemplateRows={{
        base: '100%',
        sm: '3vh 25vh 42vh',
        md: '3vh 25vh 42vh',
      }}
      gridTemplateAreas={{
        base: `
        'header'
        'map'
        'leftcard'
        'permissions'
        'leftscrollcard'`,
        sm: `
        'header'
        'map'
        'permissions'
        'rightcard'
        'leftcard'
        'leftscrollcard'`,
        md: `
        'header header header'
        'leftcard rightcard map'
        'leftscrollcard rightcard map'
        'leftscrollcard rightcard map'`,
      }}
      gap={8}
      rowGap={30}
    >
      <Flex gridArea="header">
        <Heading width={'100%'} alignItems={'center'}>
          {hub.hub_name}
        </Heading>
      </Flex>
      <Flex w={'100%'} gridArea="leftcard">
        <Card width={'100%'} overflowY="auto">
          <AssignToHub hub={hub} />
        </Card>
      </Flex>
      <Flex gridArea="leftscrollcard">
        <Card width={'100%'}>
          <FlagBox hub={hub} />
        </Card>
      </Flex>
      <Box gridArea="map">
        <SingleHubMap hub={hub} />
      </Box>
      <Flex gridArea="rightcard">
        <HubInfoCard hub={hub} />
      </Flex>
      <Flex gridArea={'permissions'}></Flex>
    </Grid>
  );
}

export function ManageHub() {
  const [hub, setHub] = useState<HubDTO>();
  const { hub_id } = useParams();

  useEffect(() => {
    if (hub_id) {
      HubAPI(hub_id).then((res) => {
        setHub(res);
      });
    }
  }, [hub_id]);

  if (!hub) {
    return <Text>{t('core_not_found')}</Text>;
  }

  return <HubBoxCard hub={hub} />;
}
