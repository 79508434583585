import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import no from './no.json';
import Cookies from 'js-cookie';

export function initializeI18n() {
  use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      no: {
        translation: no,
      },
    },
    lng: Cookies.get('lng'),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

  return i18n;
}
