import { AdminUserViewDTO } from '../../../dto';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { iquColor, warningColor } from '../../../styles';
import { t } from 'i18next';

interface dataInterface {
  user: AdminUserViewDTO;
}

export function UserEditModal({ user }: dataInterface) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);
  const [isStaff, setIsStaff] = useState(user.is_staff);

  return (
    <>
      <IconButton bg={iquColor} color={'white'} icon={<AiFillEdit />} aria-label="Edit" onClick={onOpen} />

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('edit')} {user.username}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t('first_name')}</FormLabel>
              <Input
                ref={initialRef}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t('last_name')}</FormLabel>
              <Input
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t('email')}</FormLabel>
              <Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t('phone_number')}</FormLabel>
              <Input
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t('admin')}</FormLabel>
              <Checkbox
                checked={isStaff}
                onChange={(e) => {
                  setIsStaff(e.target.checked);
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button bg={iquColor} mr={3}>
              {t('save')}
            </Button>
            <Button onClick={onClose} bg={warningColor}>
              {t('cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
