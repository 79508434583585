import { HubDTO, HubWithFlagDTO } from '../../dto';
import { MapComponent } from '../map/MapComponent';
import Green from '../../assets/images/circle-dot-green.svg';
import Yellow from '../../assets/images/circle-dot-yellow.svg';
import { GetHubFlagsAPI } from '../../apiService';
import { useState } from 'react';
import { IsOnline } from '../TimeUtils/TimeUtils';
import Red from '../../assets/images/circle-dot-red.svg';

interface HubInterface {
  hub: HubDTO;
}

export function SingleHubMap({ hub }: HubInterface) {
  const center = { lat: Number(hub.latitude), lng: Number(hub.longitude) };
  const [hasFlags, setHasFlags] = useState(false);
  GetHubFlagsAPI(hub.hub_id).then((res) => {
    if (Array.isArray(res) && res.length > 0) {
      setHasFlags(true);
    }
  });
  const ColorToUse = (hub: HubWithFlagDTO) => {
    if (hasFlags && IsOnline(hub)) {
      return Yellow;
    }
    if (!IsOnline(hub)) {
      return Red;
    }
    return Green;
  };

  const markers = [
    {
      key: hub.hub_name,
      position: center,
      title: hub.hub_name,
      hub_data: hub,
      icon: {
        url: ColorToUse(hub),
        anchor: { x: 20, y: 20 } as google.maps.Point,
      },
    },
  ];

  return <MapComponent center={center} markers={markers} />;
}
