import axios, { AxiosResponse } from 'axios';
import {
  AdminUserViewDTO,
  configStatusDTO,
  FlagDataDTO,
  FlagDto,
  HubDTO,
  HubsPermissionsDTO,
  HubWithFlagDTO,
  InviteByEmailDTO,
  LoginDto,
  NotificationDTO,
  TwoFactorDto,
  UpdateInfoDTO,
  UserDTO,
  UserInvitesDto,
  UserPermissionsDTO,
} from './dto';
import Cookies from 'js-cookie';

const AuthHeader = () => {
  return { Authorization: 'Bearer ' + Cookies.get('accessToken') };
};

const baseUrl = () => {
  return process.env.REACT_APP_BACKEND_URL as string;
};

export async function Login(userName: string, password: string): Promise<LoginDto> {
  const response = await axios.post<LoginDto>(baseUrl() + 'api/token/', {
    username: userName,
    password: password,
  });
  return response.data;
}

export async function TwoFactorAuthAPI(code: string): Promise<number> {
  const response = await axios.post<TwoFactorDto>(
    baseUrl() + 'api/two-factor-auth/',
    {
      code: code,
    },
    {
      headers: AuthHeader(),
    },
  );
  return response.status;
}

export async function VerifyToken(): Promise<boolean> {
  try {
    const response = await axios.post(baseUrl() + 'api/token/verify/', { token: Cookies.get('accessToken') });
    return response.status == 200;
  } catch (error) {
    return false;
  }
}

export async function IsTwoFactorAuthAPI(): Promise<boolean> {
  try {
    const response = await axios.get(baseUrl() + 'api/two-factor-auth/', { headers: AuthHeader() });
    return response.status === 200;
  } catch (error) {
    return false;
  }
}

export async function AllHubs(): Promise<HubDTO[]> {
  const response = await axios.get(baseUrl() + 'api/hub/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function HubAPI(hubId: string): Promise<HubDTO> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hubId, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function UserAPI(): Promise<UserDTO> {
  const response = await axios.get(baseUrl() + 'api/me/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function AllUserAPI(): Promise<[AdminUserViewDTO]> {
  const response = await axios.get(baseUrl() + 'api/user/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetUserAPI(user_id: string): Promise<AdminUserViewDTO> {
  const response = await axios.get(baseUrl() + 'api/user/' + user_id, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function AllFlags(queryString: string = ''): Promise<FlagDataDTO> {
  const response = await axios.get(baseUrl() + `api/flag/${queryString}`, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetHubFlagsAPI(hub_id: string): Promise<FlagDto> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/flag/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetFlagAPI(hub_id: string, flag_id: string): Promise<FlagDto> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/flag/' + flag_id, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetHubWithFlags(): Promise<HubWithFlagDTO[]> {
  const response = await axios.get(baseUrl() + 'api/hubs/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function DeactivateFlag(hub_id: string, flag_id: number): Promise<FlagDto[]> {
  const response = await axios.put(
    baseUrl() + 'api/hub/' + hub_id + '/flag/' + flag_id + '/',
    { active: false },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function SendHubCommand(
  hub_id: string,
  command: string,
  files: Record<string, File> | null,
): Promise<AxiosResponse<unknown, unknown>> {
  const formData = new FormData();
  formData.append('command', command);
  files && Object.entries(files).forEach(([key, value]) => formData.append(key, value));

  return await axios.post(baseUrl() + 'api/hub/' + hub_id + '/commands/', formData, {
    headers: {
      ...AuthHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function UpdateHub(hub_id: string, data: { is_authenticated: boolean }): Promise<number> {
  const response = await axios.put(baseUrl() + 'api/hub/' + hub_id + '/', { data }, { headers: AuthHeader() });
  return response.status;
}

export async function SendUserInvite(email: string): Promise<number> {
  const response = await axios.post(baseUrl() + 'api/user-invite/', { email: email }, { headers: AuthHeader() });
  return response.status;
}

export async function UpdateUserInvite(email: string, time: number): Promise<number> {
  const response = await axios.put(
    baseUrl() + 'api/user-invite/',
    { email: email, time: time },
    { headers: AuthHeader() },
  );
  return response.status;
}

export async function DeleteUserInvite(email: string): Promise<number> {
  const response = await axios.delete(baseUrl() + 'api/user-invite/', {
    headers: AuthHeader(),
    data: { email: email },
  });
  return response.status;
}

export async function GetUserInvites(): Promise<UserInvitesDto[]> {
  const response = await axios.get(baseUrl() + 'api/user-invite/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetCreateUser(inviteId: string): Promise<InviteByEmailDTO> {
  const response = await axios.get(baseUrl() + 'api/invite/' + inviteId + '/');
  return response.data;
}

export async function GetHubPermissions(hub_id: string): Promise<[HubsPermissionsDTO]> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/permissions/', { headers: AuthHeader() });
  return response.data;
}

export async function PostHubPermissions(
  hub_id: string,
  user_id: string,
  can_delete: boolean,
  update: boolean,
  view: boolean,
  add_users: boolean,
): Promise<number> {
  const data = {
    user_id: user_id,
    delete: can_delete,
    update: update,
    view: view,
    add_users: add_users,
  };
  const response = await axios.post(
    baseUrl() + 'api/hub/' + hub_id + '/permissions/',
    { data },
    { headers: AuthHeader() },
  );
  return response.status;
}

export async function GetProfilePicture(): Promise<string | null> {
  try {
    const response = await axios.get(baseUrl() + 'api/me/picture/', { headers: AuthHeader(), responseType: 'blob' });
    if (response.status === 404) {
      return null;
    }
    return URL.createObjectURL(response.data);
  } catch (error) {
    return null;
  }
}

export async function GetProfileAdminPicture(user_id: string): Promise<string | null> {
  try {
    const response = await axios.get(baseUrl() + 'api/user/' + user_id + '/picture', {
      headers: AuthHeader(),
      responseType: 'blob',
    });
    if (response.status === 404) {
      return null;
    }
    return URL.createObjectURL(response.data);
  } catch (error) {
    return null;
  }
}

export async function GetUserAdminPermissions(user_id: string): Promise<[UserPermissionsDTO]> {
  const response = await axios.get(baseUrl() + 'api/user/' + user_id + '/permissions/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function UploadProfilePicture(imageFile: File): Promise<string> {
  const formData = new FormData();
  formData.append('profile_picture', imageFile);

  const response = await axios.post(baseUrl() + 'api/me/picture/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + Cookies.get('accessToken'),
    },
    responseType: 'blob',
  });
  return URL.createObjectURL(response.data);
}

export async function CreateUserAPI(
  inviteId: string,
  username: string,
  phone_number: string,
  password: string,
  first_name: string,
  last_name: string,
): Promise<number> {
  const response = await axios.post(baseUrl() + 'api/invite/' + inviteId + '/', {
    username: username,
    phone_number: phone_number,
    password: password,
    first_name: first_name,
    last_name: last_name,
  });
  return response.status;
}

export async function GetNotifications(): Promise<NotificationDTO[]> {
  const response = await axios.get(baseUrl() + 'api/notifications/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function ViewedNotification(id: number, viewed: boolean): Promise<NotificationDTO> {
  const response = await axios.put(
    baseUrl() + 'api/notification/' + id + '/',
    { viewed: viewed },
    {
      headers: AuthHeader(),
    },
  );
  return response.data;
}

export async function ViewedNotifications(notification_ids: number[], viewed: boolean): Promise<NotificationDTO> {
  const response = await axios.put(
    baseUrl() + 'api/notifications/',
    { notification_ids: notification_ids, viewed: viewed },
    {
      headers: AuthHeader(),
    },
  );
  return response.data;
}

export async function GetConfig(hub_id: string) {
  return await axios.get(baseUrl() + 'api/hub/' + hub_id + '/config', {
    headers: AuthHeader(),
    responseType: 'blob',
  });
}
export async function GetConfigStatus(hub_id: string): Promise<configStatusDTO> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/config/status', {
    headers: AuthHeader(),
  });
  return response.data;
}
export async function GetVersion() {
  const response = await axios.get(baseUrl() + 'api/version');
  return response.data;
}

export async function GetUpdateInfo(hub_id: string): Promise<UpdateInfoDTO> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/update/', {
    headers: AuthHeader(),
  });
  return response.data;
}
