import { Card, CardBody, Divider, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { LiaSmsSolid } from 'react-icons/lia';
import { ViewedNotification } from '../../apiService';
import { NotificationDTO } from '../../dto';

interface FlagProps {
  notification: NotificationDTO;
  setNotifications: (data: NotificationDTO[]) => void;
  notifications: NotificationDTO[];
}

export function FlagNotification({ notification, setNotifications, notifications }: FlagProps) {
  function DeleteNotification(notificationId: number) {
    setNotifications(notifications.filter((notification: { id: number }) => notification.id !== notificationId));
  }

  return (
    <Card
      mb={2}
      borderColor={notification.label_color}
      borderLeftWidth={2}
      backgroundColor={notification.label_color + '11'}
    >
      <Link
        to={notification.endpoint}
        style={{ width: '100%' }}
        onClick={() => {
          DeleteNotification(notification.id);
          ViewedNotification(notification.id, true);
        }}
      >
        <CardBody>
          <Flex w={'100%'}>
            <Flex mt={'-5'} w={'100%'} gap={2}>
              <Text fontSize="xs" color="gray.500" mb={'-2'}>
                {notification.label + ' - ' + notification.level}
              </Text>
            </Flex>
            <Flex mt={'-5'} w={'100%'} gap={2} justifyContent={'center'}>
              {notification.send_sms && <LiaSmsSolid color="#718096" />}
              {notification.send_email && <MdOutlineMarkEmailRead color="#718096" />}
            </Flex>
            <Flex justifyContent={'end'} mt={'-5'} w={'100%'}>
              <Text fontSize="xs" color="gray.500" mb={'-2'}>
                {formatDistanceToNow(new Date(notification.time), { addSuffix: true })}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex gap={2} align={'center'} mt={2}>
            <Text fontSize={14}>{notification.message.substring(0, 60) + '...'}</Text>
          </Flex>
        </CardBody>
      </Link>
    </Card>
  );
}
