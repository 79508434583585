import { useEffect, useState } from 'react';
import { AllHubs } from '../../apiService';
import { HubDTO } from '../../dto';
import { Box, Center, Input, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { HubCard } from '../../components/hub/HubCard';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { t } from 'i18next';

export function AllHubsPage() {
  const [hubs, setHubs] = useState<HubDTO[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    AllHubs().then((r) => setHubs(r));
  }, []);

  const filteredHubs = hubs.filter(
    (hub) =>
      hub.hub_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      hub.hub_id?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      hub.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      hub.post_code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      hub.address?.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (!hubs) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Center>
        <VStack width={'100%'}>
          <Text size={'xl'} as={'b'}>
            {t('search')}
          </Text>
          <Input
            type="text"
            placeholder={t('Search_by')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            marginBottom="16px"
            width={{ base: '95%', md: '30%' }}
          />
        </VStack>
      </Center>
      <Center>
        <SimpleGrid minChildWidth="320px" spacing="20px" width={'95%'} paddingTop={'2%'}>
          {filteredHubs.map((hub) => (
            <HubCard key={hub.hub_id + '_hub_card'} hub={hub} />
          ))}
        </SimpleGrid>
      </Center>
    </Box>
  );
}
