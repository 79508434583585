import { SimpleGrid } from '@chakra-ui/react';
import { AdminUserViewDTO } from '../../../dto';
import { UserCardAdmin } from './UserCardAdmin';

interface UserListTableInterface {
  users: [AdminUserViewDTO];
}

export function UserListTable({ users }: UserListTableInterface) {
  return (
    <SimpleGrid minChildWidth={'300px'} spacing={'10px'}>
      {users.map((user, key) => {
        return <UserCardAdmin showSettings={true} key={key} user={user} />;
      })}
    </SimpleGrid>
  );
}
