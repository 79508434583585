import { GetUserAdminPermissions } from '../../../apiService';
import { useEffect, useState } from 'react';
import { UserPermissionsDTO } from '../../../dto';
import { Card, CardBody, CardHeader, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { CiCircleCheck, CiCircleRemove } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

interface UserPermissionsInterface {
  user_id: string;
}

interface PermissionCardInterface {
  permission: UserPermissionsDTO;
}

interface GoodOrErrorIconInterface {
  allowed: boolean;
}

function GoodOrErrorIcon({ allowed }: GoodOrErrorIconInterface) {
  if (allowed) {
    return <CiCircleCheck color={'green'} size={50} />;
  }
  return <CiCircleRemove color={'red'} size={50} />;
}

function PermissionCard({ permission }: PermissionCardInterface) {
  return (
    <Tr>
      <Td>
        <Tooltip label={permission.hub__hub_name}>
          <Link to={'/core/' + permission.hub}>
            {permission.hub__hub_name.length < 10 ? permission.hub__hub_name : permission.hub__hub_name.slice(0, 10)}
          </Link>
        </Tooltip>
      </Td>
      <Td>
        <GoodOrErrorIcon allowed={permission.is_owner} />
      </Td>
      <Td>
        <GoodOrErrorIcon allowed={permission.view} />
      </Td>
      <Td>
        <GoodOrErrorIcon allowed={permission.delete} />
      </Td>
      <Td>
        <GoodOrErrorIcon allowed={permission.add_users} />
      </Td>
      <Td>
        <GoodOrErrorIcon allowed={permission.update} />
      </Td>
    </Tr>
  );
}

export function UserPermissions({ user_id }: UserPermissionsInterface) {
  const [permissions, setPermissions] = useState<[UserPermissionsDTO]>();

  useEffect(() => {
    GetUserAdminPermissions(user_id).then((r) => {
      setPermissions(r);
    });
  }, [user_id]);

  if (!permissions) {
    return <LoadingSpinner />;
  }

  return (
    <Card>
      <CardHeader>
        <Text>{t('permissions')}</Text>
      </CardHeader>
      <CardBody>
        <Table>
          <Thead>
            <Tr>
              <Th>{t('name')}</Th>
              <Th>{t('owner')}</Th>
              <Th>{t('view')}</Th>
              <Th>{t('delete')}</Th>
              <Th>{t('add_users')}</Th>
              <Th>{t('update')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {permissions.map((permission, key) => {
              return <PermissionCard key={key} permission={permission} />;
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
