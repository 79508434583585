import { SetStateAction, useEffect, useState } from 'react';
import { HubWithFlagDTO, MapMarkerDTO } from '../../dto';
import Yellow from '../../assets/images/circle-dot-yellow.svg';
import Green from '../../assets/images/circle-dot-green.svg';
import Red from '../../assets/images/circle-dot-red.svg';
import { Box, Input, SimpleGrid, VStack } from '@chakra-ui/react';
import { SmallHubCard } from '../hub/info/SmallHubCard';
import { MapComponent } from '../map/MapComponent';
import { MapMarkerWindow } from '../stats/MapMarkerWindow';
import { IsOnline } from '../TimeUtils/TimeUtils';
import { t } from 'i18next';

interface HomePageInfoInterface {
  hubs: HubWithFlagDTO[];
}

export function HomePageMap({ hubs }: HomePageInfoInterface) {
  const [markers, setMarkers] = useState<MapMarkerDTO[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<MapMarkerDTO | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [zoom, setZoom] = useState(6);

  const [center, setCenter] = useState({
    lat: 60.2553909778811,
    lng: 11.682766697499536,
  });

  const filteredHubs = hubs
    .filter(
      (hub) =>
        hub.hub_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        hub.hub_id?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        hub.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        hub.post_code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        hub.address?.toLowerCase().includes(searchQuery.toLowerCase()),
    )
    .slice()
    .sort((a, b) => {
      const nameA = a.hub_name.toLowerCase();
      const nameB = b.hub_name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  const hasFlags = (hub: HubWithFlagDTO) => {
    return Array.isArray(hub.flags) && hub.flags.length > 0;
  };

  const ColorToUse = (hub: HubWithFlagDTO) => {
    if (hasFlags(hub) && IsOnline(hub)) {
      return Yellow;
    }
    if (!IsOnline(hub)) {
      return Red;
    }
    return Green;
  };

  function SetSelectedHubMarker(hub: HubWithFlagDTO) {
    setZoom(10);
    setCenter({ lat: Number(hub.latitude), lng: Number(hub.longitude) });
    setSelectedMarker({
      key: hub.hub_id,
      position: { lat: Number(hub.latitude), lng: Number(hub.longitude) },
      title: hub.hub_name,
      hub_data: hub,
      icon: {
        url: ColorToUse(hub),
        anchor: { x: 20, y: 20 } as google.maps.Point,
      },
    });
  }

  const hubMarkers = (hubs: HubWithFlagDTO[]) => {
    const markersList: MapMarkerDTO[] = [];

    hubs.map((hub) => {
      {
        markersList.push({
          key: hub.hub_id,
          position: { lat: Number(hub.latitude), lng: Number(hub.longitude) },
          title: hub.hub_name,
          hub_data: hub,
          icon: {
            url: ColorToUse(hub),
            anchor: { x: 20, y: 20 } as google.maps.Point,
          },
        });
      }
    });
    return markersList;
  };

  useEffect(() => {
    setMarkers(hubMarkers(hubs));
    // Creates infinite recursion if added dependencies as eslint wants
    // eslint-disable-next-line
  }, [hubs]);

  return (
    <SimpleGrid columns={[1, 1, 2]}>
      <Box
        w={'100%'}
        padding={{ base: '0', md: '15px' }}
        order={{ base: '1', md: '0' }}
        alignItems="center"
        h={{ base: '44vh', md: '88vh' }}
        overflow="hidden"
      >
        <VStack>
          <Input
            type="text"
            placeholder={t('search')}
            value={searchQuery}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setSearchQuery(e.target.value)}
          />
          <Box
            className="custom-scrollbar"
            flexWrap="wrap"
            justifyContent="center"
            alignContent="flex-start"
            maxH="80vh"
            minH="60vh"
            mt={{ base: '0', md: '20px' }}
            overflowY="auto"
            borderRadius="10px"
            w={'100%'}
            padding={'15px'}
          >
            <SimpleGrid minChildWidth="285px" spacing="20px">
              {filteredHubs.map((hub) => (
                <SmallHubCard key={hub.hub_id} hub={hub} setSelectedHubMarker={SetSelectedHubMarker} />
              ))}
            </SimpleGrid>
          </Box>
        </VStack>
      </Box>

      <MapComponent
        markers={markers}
        DisplayWindow={<MapMarkerWindow hub={selectedMarker?.hub_data} />}
        setSelectedMarker={setSelectedMarker}
        selectedMarker={selectedMarker}
        zoom={zoom}
        center={center}
      />
    </SimpleGrid>
  );
}
