import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Table,
  Tbody,
  Td,
  Tr,
  Spinner,
  Text,
  IconButton,
  Tooltip,
  Icon,
  HStack,
  Divider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  ModalHeader,
  Heading,
  ButtonProps,
  Wrap,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { GetUpdateInfo } from '../../apiService';
import { MdOutlineSystemUpdateAlt } from 'react-icons/md';
import { RiFolderWarningLine } from 'react-icons/ri';
import { FaArrowRotateRight, FaRegCircleXmark } from 'react-icons/fa6';
import { FaRegCheckCircle } from 'react-icons/fa';
import { EditIcon } from '@chakra-ui/icons';

interface UpdateMenuInterface {
  hub_id: string;
  handleClick: (command: string) => void;
}

export function UpdateMenuModal({ hub_id, handleClick }: UpdateMenuInterface) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
  const cancelRef = React.useRef(null);
  const [version, setVersion] = useState('');
  const [isNewestVersion, setIsNewestVersion] = useState(false);
  const [DateTime, setDateTime] = useState('');
  const [tag, setTag] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [newTag, setNewTag] = useState(tag);

  useEffect(() => {
    if (isOpen) {
      GetUpdateInfo(hub_id).then((r) => {
        setIsLoading(false);
        setVersion(r.version);
        setTag(r.tag);
        setIsNewestVersion(r.is_newest_version);
        setDateTime(r.datetime);
      });
    }
  }, [hub_id, isOpen, refresh]);

  if (isOpen && isLoading) {
    return (
      <Center>
        <Spinner size={'lg'} />
      </Center>
    );
  }

  function ActionButton({ tooltip, ...props }: { tooltip: string } & ButtonProps) {
    return (
      <Tooltip label={tooltip}>
        <Button {...props} />
      </Tooltip>
    );
  }

  const actions = (
    <Wrap>
      <ActionButton
        disabled={isNewestVersion}
        tooltip={'Update'}
        colorScheme={'green'}
        isDisabled={!isNewestVersion}
        leftIcon={<MdOutlineSystemUpdateAlt />}
        onClick={() => handleClick('update')}
      >
        Update
      </ActionButton>
      <ActionButton
        tooltip={
          'Force update.' +
          ' Warning, this bypasses the update checks and will update and reboot the system if non is available.'
        }
        colorScheme={'red'}
        leftIcon={<RiFolderWarningLine />}
        onClick={() => handleClick('force-update')}
      >
        Force update
      </ActionButton>
      <ActionButton tooltip={'Change tag'} leftIcon={<EditIcon />} onClick={onOpenAlert} colorScheme={'blue'}>
        Change tag
      </ActionButton>
      <AlertDialog onClose={onCloseAlert} isOpen={isOpenAlert} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Change tag</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text>
              This will change the docker tag. This can be destructive. When applying the core will restart and update
              to the new tag.
            </Text>
            <Input
              defaultValue={newTag}
              onChange={(e) => {
                setNewTag(e.target.value);
              }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseAlert}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClick('new-tag' + '?tag=' + newTag);
                onCloseAlert();
              }}
              colorScheme="red"
              ml={3}
            >
              Apply
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Wrap>
  );

  return (
    <>
      <Button onClick={onOpen}>Update menu</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack justify="space-between">
              <Heading as="h2" size="md">
                Update menu
              </Heading>
              <IconButton
                onClick={() => setRefresh(!refresh)}
                aria-label="Refresh"
                icon={<FaArrowRotateRight />}
                colorScheme="blue"
                size="sm"
              />
            </HStack>
          </ModalHeader>
          <ModalBody>
            <Table variant={'striped'}>
              <Tbody>
                <Tr w="100%">
                  <Td textAlign="center">Version</Td>
                  <Tooltip label={'Click to copy: ' + version}>
                    <Td
                      display="flex"
                      justifyContent="center"
                      onClick={() => {
                        navigator.clipboard.writeText(version);
                      }}
                    >
                      <Text maxW="25ch" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                        {version}
                      </Text>
                    </Td>
                  </Tooltip>
                </Tr>

                <Tr>
                  <Td textAlign="center">Is newest version</Td>
                  <Td textAlign="center">
                    {isNewestVersion ? (
                      <Icon boxSize={8} as={FaRegCheckCircle} color={'green'} />
                    ) : (
                      <Icon boxSize={8} as={FaRegCircleXmark} color={'red'} />
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign="center">DateTime</Td>
                  <Td textAlign="center">{DateTime}</Td>
                </Tr>
                <Tr>
                  <Td textAlign="center">Tag</Td>
                  <Td textAlign="center">{tag}</Td>
                </Tr>
              </Tbody>
            </Table>
            <Divider padding={2} />
            <Center padding={2} width={'100%'}>
              <HStack width="100%">{actions}</HStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
