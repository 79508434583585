export class QueryString {
  query_string: string;
  query_items: number = 0;

  constructor() {
    this.query_string = '?';
  }

  add_kwarg(key: string, val: string) {
    let prefix = '';
    if (this.query_items !== 0) {
      prefix = '&';
    }
    this.query_string += prefix + key + '=' + val;
    this.query_items += 1;
  }
}
