import { NotificationDTO } from '../../dto';
import { Box, Button, Center, Divider, Flex, Text, useColorMode } from '@chakra-ui/react';
import { MessageNotification } from './Message';
import { FlagNotification } from './Flag';
import { ViewedNotifications } from '../../apiService';
import { t } from 'i18next';

interface NotificationsInterface {
  notifications: NotificationDTO[];
  setNotifications: (data: NotificationDTO[]) => void;
  hasNotifications: boolean;
}

export function Notifications({ notifications, setNotifications, hasNotifications }: NotificationsInterface) {
  const isDarkMode = useColorMode().colorMode === 'dark';
  const notification_ids = notifications.map((notification) => notification.id);

  const notificationsHeader = (
    <Flex w={'100%'} align={'center'} backgroundColor={isDarkMode ? 'gray.600' : 'gray.100'} p={2}>
      <Text fontSize="2xl" fontWeight="bold" textAlign="center">
        {t('notifications')}
      </Text>
      <Flex w={'100%'} justifyContent={'flex-end'}>
        <Button
          onClick={() => {
            ViewedNotifications(notification_ids, true);
            setNotifications([]);
          }}
        >
          {t('mark_all_as_read')}
        </Button>
      </Flex>
    </Flex>
  );

  if (!hasNotifications) {
    return (
      <Box w={'100%'} h={'100%'} overflowY={'hidden'}>
        {notificationsHeader}
        <Divider />
        <Center>No notifications</Center>
      </Box>
    );
  }

  return (
    <Box w={'100%'} h={'100%'} overflowY={'hidden'}>
      {notificationsHeader}
      <Divider />
      <Box overflowY={'auto'} h={'83%'}>
        {notifications.map((notification: NotificationDTO) =>
          !notification.viewed ? (
            <Box key={notification.id + '_notification'} m={2}>
              {notification.label === 'Message' ? (
                <MessageNotification
                  notification={notification}
                  setNotifications={setNotifications}
                  notifications={notifications}
                />
              ) : (
                <FlagNotification
                  notification={notification}
                  setNotifications={setNotifications}
                  notifications={notifications}
                />
              )}
            </Box>
          ) : null,
        )}
      </Box>
    </Box>
  );
}
