import { Box, Button, Center, Flex } from '@chakra-ui/react';
import { iquColor } from '../../styles';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

interface PageSelectorInterFace {
  setCurrentPage: (currentPage: React.SetStateAction<number>) => void;
  currentPage: number;
  totalPages: number;
}
export function PageSelector({ setCurrentPage, currentPage, totalPages }: PageSelectorInterFace) {
  const [pages, setPages] = useState<number[]>([]);
  const handlePageChange = (newPage: React.SetStateAction<number>) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const pagesVar: Array<number> = [];
    for (let i = 0; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1 && i > 1 && i < totalPages)) {
        pagesVar.push(i);
      }
    }
    setPages(pagesVar);
  }, [currentPage, totalPages]);

  return (
    <Box>
      <Center>
        <Flex justifyContent="center" marginTop="20px">
          <Button
            onClick={() => {
              handlePageChange(currentPage - 1);
            }}
            backgroundColor={iquColor}
            color={'white'}
            marginInline={1}
          >
            {t('prev')}
          </Button>
          {pages.map((value) => (
            <Button
              key={value}
              onClick={() => {
                handlePageChange(value);
              }}
              backgroundColor={currentPage === value ? iquColor : ''}
              color={currentPage === value ? 'white' : ''}
              marginInline={1}
            >
              {value}
            </Button>
          ))}
          <Button
            onClick={() => {
              handlePageChange(currentPage + 1);
            }}
            backgroundColor={iquColor}
            color={'white'}
            marginInline={1}
          >
            {t('next')}
          </Button>
        </Flex>
      </Center>
    </Box>
  );
}
