import React, { useEffect } from 'react';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import mapStylesLight from './jsonStylingLight.json';
import mapStylesDark from './jsonStylingDark.json';
import { Box, Image, Text, useColorMode } from '@chakra-ui/react';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { MapMarkerDTO } from '../../dto';
import './map-style.css';
import IQULogo from '../../assets/images/iQU_transparent.png';
import { grayishBlue, whiteColor } from '../../styles';
import { t } from 'i18next';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
  borderRadius: '14px',
};

const iquHQCenter = {
  lat: 60.2553909778811,
  lng: 11.682766697499536,
};

interface MarkerInterface {
  markers: MapMarkerDTO[];
  selectedMarker?: MapMarkerDTO | null;
  setSelectedMarker?: React.Dispatch<React.SetStateAction<MapMarkerDTO | null>> | null;
  DisplayWindow?: React.ReactNode;
  center?: { lat: number; lng: number } | null;
  zoom?: number | null;
}

export function MapComponent({
  markers,
  zoom,
  center,
  selectedMarker,
  setSelectedMarker,
  DisplayWindow,
}: MarkerInterface) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });

  const isDarkMode = useColorMode().colorMode === 'dark';

  useEffect(() => {
    document.documentElement.style.setProperty('--background-color', isDarkMode ? '#171923' : '#edf2f7');
  }, [isDarkMode]);

  const handleMarkerClick = (marker: MapMarkerDTO) => {
    if (setSelectedMarker) {
      setSelectedMarker(marker);
    }
  };

  if (loadError) {
    return <Text>{t('error_loading_maps')}</Text>;
  }

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  if (!center) {
    center = iquHQCenter;
  }

  return (
    <Box position="relative" w={'100%'} h={'100%'}>
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        zIndex="2"
        borderWidth={'4px'}
        borderColor={isDarkMode ? grayishBlue : whiteColor}
        borderRadius={'10px'}
        overflowY={'hidden'}
        pointerEvents={'none'}
      />
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom ? zoom : 6}
        id="searchInputControl"
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          styles: isDarkMode ? mapStylesDark : mapStylesLight,
          streetViewControl: false,
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              east: 180,
              west: -180,
            },
            strictBounds: false,
          },
          minZoom: 2.2,
        }}
      >
        <Box
          h="24px"
          bg={isDarkMode ? grayishBlue : whiteColor}
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          zIndex="2"
        >
          <Image src={IQULogo} objectFit="contain" width={{ base: '10%', md: '5%' }} height="100%" />
        </Box>
        {markers.map((marker) => (
          <Marker
            key={marker.key}
            position={marker.position}
            title={marker.title}
            icon={marker.icon}
            onClick={() => handleMarkerClick(marker)}
          />
        ))}
        {selectedMarker && setSelectedMarker && (
          <InfoWindow position={selectedMarker.position} onCloseClick={() => setSelectedMarker(null)}>
            {DisplayWindow}
          </InfoWindow>
        )}
      </GoogleMap>
    </Box>
  );
}
