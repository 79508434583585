import { Box, Button, Card, CardHeader, Center, Divider, Flex, Heading, Stack } from '@chakra-ui/react';
import { FlagDto } from '../../dto';
import { Link } from 'react-router-dom';
import { DeactivateFlag } from '../../apiService';
import { t } from 'i18next';

interface FlagControlInterface {
  flag: FlagDto;
  hub_id: string;
  setFlag: (data: FlagDto | ((prevFlag: FlagDto) => FlagDto)) => void;
}

export function FlagControls({ flag, hub_id, setFlag }: FlagControlInterface) {
  const deactivateFlag = async () => {
    await DeactivateFlag(hub_id, flag.id);
    setFlag({ ...flag, is_active: false });
  };

  return (
    <Card h={'100%'} w={'100%'}>
      <CardHeader>
        <Center>
          <Heading fontSize={'3xl'}>{t('flag_controls_uppercase')}</Heading>
        </Center>
        <Divider />
      </CardHeader>
      <Box p={4}>
        <Center>
          <Flex
            minW={'100%'}
            flexWrap={{ base: 'wrap', md: 'nowrap' }}
            justifyContent={'center'}
            flexDirection={'column'}
            gap={2}
          >
            <Stack w={'100%'}>
              <Link to={'/core/' + hub_id}>
                <Button w={'100%'} minH={'5vh'} marginBottom={{ base: 2, md: 0 }}>
                  {t('manage_core')}
                </Button>
              </Link>
            </Stack>
            <Button w={'100%'} minH={'5vh'} marginBottom={{ base: 2, md: 0 }} onClick={deactivateFlag}>
              {t('deactivate_flag')}
            </Button>
          </Flex>
        </Center>
      </Box>
    </Card>
  );
}
