import { Card, CardBody, Divider, Flex, Text } from '@chakra-ui/react';
import { MessageWindow } from './MessageWindow';
import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { NotificationDTO } from '../../dto';

interface MessageProps {
  notification: NotificationDTO;
  setNotifications: (data: NotificationDTO[]) => void;
  notifications: NotificationDTO[];
}

export function MessageNotification({ setNotifications, notification, notifications }: MessageProps) {
  const [messageOpen, setMessageOpen] = useState<boolean>(false);

  if (messageOpen) {
    return (
      <MessageWindow notification={notification} setNotifications={setNotifications} notifications={notifications} />
    );
  }

  return (
    <Card
      mb={2}
      borderColor={notification.label_color}
      borderLeftWidth={2}
      backgroundColor={notification.label_color + '11'}
      onClick={() => {
        setMessageOpen(true);
      }}
    >
      <CardBody>
        <Flex w={'100%'}>
          <Flex mt={'-5'} w={'100%'} gap={2}>
            <Text fontSize="xs" color="gray.500" mb={'-2'}>
              {notification.label + ' - ' + notification.level}
            </Text>
          </Flex>
          <Flex justifyContent={'end'} mt={'-5'} w={'100%'}>
            <Text fontSize="xs" color="gray.500" mb={'-2'}>
              {formatDistanceToNow(new Date(notification.time), { addSuffix: true })}
            </Text>
          </Flex>
        </Flex>
        <Divider />
        <Flex gap={2} align={'center'} mt={2}>
          <Text fontSize={14}>{notification.short_message}</Text>
        </Flex>
      </CardBody>
    </Card>
  );
}
