import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { IsTwoFactorAuthAPI, VerifyToken } from '../../apiService';
import { t } from 'i18next';

type UseAuthReturnType = {
  isTwoFactor: boolean;
  loggedIn: boolean;
  accessToken: string | undefined;
  setAccessToken: (accessToken: string) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setTwoFactor: (isTwoFactor: boolean) => void;
  Logout: () => void;
};

const useAuth = (): UseAuthReturnType => {
  const [isTwoFactor, setTwoFactor] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(Cookies.get('accessToken'));

  useEffect(() => {
    setAccessToken(Cookies.get('accessToken'));
  }, []);

  useEffect(() => {
    if (accessToken) {
      VerifyToken().then((r) => {
        if (r) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
          Cookies.remove('accessToken');
        }
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (loggedIn) {
      IsTwoFactorAuthAPI().then((res) => {
        setTwoFactor(res);
      });
    }
  }, [loggedIn, isTwoFactor]);

  const handleSetLoggedIn = (loggedIn: boolean) => {
    setLoggedIn(loggedIn);
  };

  const Logout = () => {
    Cookies.remove('accessToken');
    setLoggedIn(false);
    setTwoFactor(false);
    window.location.reload();
  };

  const handleSetAccessToken = (accessToken: string) => {
    Cookies.set('accessToken', accessToken, { expires: 1, sameSite: 'Lax', secure: false });
    setAccessToken(Cookies.get('accessToken'));
  };

  const handleSetTwoFactor = (isTwoFactor: boolean) => {
    setTwoFactor(isTwoFactor);
  };

  return {
    isTwoFactor: isTwoFactor,
    loggedIn: loggedIn,
    accessToken: accessToken,
    setAccessToken: handleSetAccessToken,
    setLoggedIn: handleSetLoggedIn,
    setTwoFactor: handleSetTwoFactor,
    Logout: Logout,
  };
};

interface AuthContextProps extends UseAuthReturnType {}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useGlobalAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(t('useglobalguth_must_be_used_within_an_guthprovider'));
  }
  return context;
};
