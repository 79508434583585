import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { UserDTO } from '../../dto';
import { UserAPI } from '../../apiService';
import { AccessDenied } from '../../components/admin/AccesDenied';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { t } from 'i18next';

interface AdminCardInterFace {
  heading: string;
  task: string;
  href: string;
}

function AdminCard({ heading, task, href }: AdminCardInterFace) {
  return (
    <Card align="center" variant={'outline'}>
      <CardHeader>
        <Heading size="md">{heading}</Heading>
      </CardHeader>
      <Divider />
      <CardBody>
        <Link to={href}>
          <Button>{task}</Button>
        </Link>
      </CardBody>
    </Card>
  );
}

export function AdminPage() {
  const [user, setUser] = useState<UserDTO>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    UserAPI().then((res) => {
      setUser(res);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!user?.is_staff) {
    return <AccessDenied />;
  }

  return (
    <Box gap={6}>
      <Center>
        <Heading>
          <VStack>
            <Text>{t('administrative_page')}</Text>
          </VStack>
        </Heading>
      </Center>
      <Divider padding={15} />
      <Divider padding={15} />
      <SimpleGrid minChildWidth="350px" spacing="20px">
        <AdminCard heading={t('users')} task={t('view_manage_users')} href={'/admin/users'} />
        <AdminCard heading={t('cores')} task={t('graphs_for_hubs_users_flags')} href={'/admin/stats'} />
        <AdminCard heading={t('add_user')} task={t('add_user')} href={'/admin/add-user'} />
        <AdminCard heading={t('big_counter')} task={t('big_counter')} href={'/admin/big-stat'} />
      </SimpleGrid>
    </Box>
  );
}
