import { Card, CardBody, CardHeader, Center, Divider, Heading, Text } from '@chakra-ui/react';
import { FlagDto } from '../../dto';
import { t } from 'i18next';

interface FlagInfoInterface {
  flag: FlagDto;
}

export function FlagInfo({ flag }: FlagInfoInterface) {
  const levels = ['Warning', 'Error', 'Critical'];
  return (
    <Card h={'100%'} w="100%">
      <CardHeader>
        <Center>
          <Heading fontSize={'3xl'}>{t('flag_info_uppercase')}</Heading>
        </Center>
        <Divider />
      </CardHeader>
      <CardBody>
        <Text fontSize={'sm'} margin={2}>
          {t('flag_id')}: {flag.id}
        </Text>
        <Divider />
        <Text fontSize={'sm'} margin={2}>
          {t('core_id')}: {flag.hub}
        </Text>
        <Divider />
        <Text fontSize={'sm'} margin={2}>
          {t('level')}: {levels[typeof flag.level === 'number' ? flag.level : 0]}
        </Text>
        <Divider />
        <Text fontSize={'sm'} margin={2}>
          {t('message')}: {flag.message}
        </Text>
        <Divider />
        <Text fontSize={'sm'} margin={2}>
          {t('time')}
          {': '}
          {new Date(flag.time).toLocaleString(undefined, {
            hour12: false,
          })}
        </Text>
        <Divider />
        <Text fontSize={'sm'} margin={2}>
          {t('module')}: {flag.module}
        </Text>
        <Divider />
        <Text fontSize={'sm'} margin={2}>
          {t('is_active')}: {flag.is_active ? t('yes') : t('no')}
        </Text>
      </CardBody>
    </Card>
  );
}
